<template>
    <nav class="pagination">
        <ul class="pagination__list">
            <li class="pagination__item"
                :class="{ 'pagination__item_disabled': value === 1}"
            >
                <a class="pagination__link pagination__link_arrow" href="#" aria-label="Предыдущая"
                    @click.prevent="setPage(value - 1)"
                >
                    <span aria-hidden="true">«</span>
                </a>
            </li>
            <li class="pagination__item"
                v-if="value !== 1"
            >
                <a class="pagination__link" href="#" @click.prevent="setPage(1)">1</a>
            </li>
            <li class="pagination__item pagination__item_empty" v-if="(value - 1) >= 3">...</li>
            <li class="pagination__item"
                v-if="value >= 3"
            >
                <a class="pagination__link" href="#" @click.prevent="setPage(value - 1)">
                    {{value - 1}}
                </a>
            </li>
            <li class="pagination__item pagination__item_active">
                <a class="pagination__link" href="#" @click.prevent>
                    {{value}}
                </a>
            </li>
            <li class="pagination__item"
                v-if="(total - value) >= 2"
            >
                <a class="pagination__link" href="#" @click.prevent="setPage(value + 1)">
                    {{value + 1}}
                </a>
            </li>
            <li class="pagination__item pagination__item_empty" v-if="(total - value) >= 3">...</li>
            <li class="pagination__item"
                v-if="value !== total"
            >
                <a class="pagination__link" href="#" @click.prevent="setPage(total)">{{total}}</a>
            </li>
            <li class="pagination__item"
                :class="{ 'pagination__item_disabled': value === total}"
            >
                <a class="pagination__link pagination__link_arrow" href="#" aria-label="Следующая"
                    @click.prevent="setPage(value + 1)"
                >
                    <span aria-hidden="true">»</span>
                </a>
            </li>
        </ul>
    </nav>
</template>

<script>
    export default {
        name: "Pagination",
        props: {
            value: {
                type: Number,
                required: true
            },
            total: {
                type: Number,
                required: true
            },
        },
        computed: {

        },
        methods: {
            setPage(page) {
                this.$emit("input", page);
            }
        }
    }
</script>

<style lang="scss">
    .pagination__list {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: stretch;
    }
    .pagination__item {
        &:first-child .pagination__link {
            border-top-left-radius: .25rem;
            border-bottom-left-radius: .25rem;
        }

        &:last-child .pagination__link {
            border-top-right-radius: .25rem;
            border-bottom-right-radius: .25rem;
        }

        &_empty {
            padding: .375rem .75rem;
        }

        &_disabled .pagination__link {
            color: #6c757d;
            pointer-events: none;
            background-color: #fff;
            border-color: #dee2e6;
        }

        &_active .pagination__link {
            color: #fff !important;
            background-color: $red-primary !important;
            border-color: $red-primary !important;
        }
    }
    .pagination__link {
        display: block;
        padding: .375rem .75rem;
        color: $red-secondary;
        background-color: #fff;
        border: 1px solid #dee2e6;

        transition-property: color, background-color, border-color;
        transition-duration: 150ms;

        &:hover {
            background-color: #e9ecef;
            border-color: #dee2e6;
        }
    }
</style>

<template>
    <div class="unit-detail2">
        <router-link class="unit-detail2__link unit-detail2__link_back link link_red"
            ref="backLink"
            v-if="type !== 'total' && !isUserWithOneRestaurant"
            :to="{ name: 'UnitDetail2' }"
        >
            Вернуться к общей Unit-экономике
        </router-link>
        <section class="unit-detail2__settings">
            <h2 class="sr-only">Настройки отчета "Unit-экономика 2"</h2>
            <form class="unit-detail2__controls" @submit.prevent>
                <div class="unit-detail2__dateranges">
                    <daterange-picker class="unit-detail2__daterange"
                        v-model="reportDaterangeModel"
                        name="reportDaterange"
                    >
                        Период отчета:
                    </daterange-picker>
                    <daterange-picker class="unit-detail2__daterange"
                        v-if="modeId === 'compareOtherPeriod'"
                        v-model="compareDaterangeModel"
                        name="compareOtherPeriod"
                    >
                        Период сравнения:
                    </daterange-picker>
                </div>
                <div class="unit-detail2__toggles-pagination">
                    <div class="unit-detail2__toggles">
                        <toggle-button class="unit-detail2__toggle-input"
                            :color="toggleColors"
                            v-model="modeModel"
                            :width="130"
                            :labels="modeToggleLabels"
                        />
                        <toggle-button class="unit-detail2__toggle-input"
                            :color="toggleColors"
                            v-model="sourceModel"
                            :width="100"
                            :labels="sourceToggleLabels"
                        />
                        <checkbox v-model="showWageGroupsModel"
                            v-if="modeId === 'base' && sourceId === 'orders'"
                        >
                            Показывать группы ФОТ
                        </checkbox>
                        <toggle-button class="unit-detail2__toggle-input"
                            v-if="type === 'total' && sourceId === 'distribution'"
                            :color="toggleColors"
                            v-model="distributionModel"
                            :width="80"
                            :labels="distributionToggleLabels"
                        />
                        <toggle-button class="unit-detail2__toggle-input"
                            v-if="sourceId === 'distribution'"
                            :color="toggleColors"
                            v-model="viewModel"
                            :width="80"
                            :labels="viewToggleLabels"
                        />
                    </div>
                    <pagination class="unit-detail2__pagination"
                        v-if="modeId === 'base' && sourceId === 'orders' && pagesTotalCount > 1"
                        v-model="ordersPageModel"
                        :total="pagesTotalCount"
                    />
                </div>
                <select class="unit-detail2__select"
                    v-if="sourceId === 'distribution' && viewId === 'chart'"
                    v-model="chartFieldModel"
                >
                    <option class="unit-detail2__option"
                        v-for="unitField in unitFields"
                        :key="unitField.id"
                        :value="unitField.id"
                    >
                        {{unitField.title}}
                    </option>
                </select>
            </form>
        </section>
        <spinner class="unit-detail2__spinner" v-if="status === 'loading'" />
        <error-message class="unit-detail2__error-message" v-else-if="status === 'error'">
            Не удалось загрузить данные отчета "Unit-экономика 2".
        </error-message>
        <section class="unit-detail2__data" v-else-if="status === 'success'">
            <div class="unit-detail2__base-distribution-table"
                v-if="modeId === 'base' && sourceId === 'distribution' && viewId === 'table'"
            >
                <div class="unit-detail2__box box">
                    <table class="unit-detail2__table unit-detail2__table_striped">
                        <colgroup>
                            <col class="unit-detail2__table-column unit-detail2__table-column_distribution-title">
                        </colgroup>
                        <colgroup>
                            <col class="unit-detail2__table-column unit-detail2__table-column_distribution">
                        </colgroup>
                        <colgroup>
                            <col class="unit-detail2__table-column unit-detail2__table-column_distribution">
                        </colgroup>
                        <colgroup>
                            <col class="unit-detail2__table-column unit-detail2__table-column_distribution">
                        </colgroup>
                        <colgroup>
                            <col class="unit-detail2__table-column unit-detail2__table-column_distribution">
                            <col class="unit-detail2__table-column unit-detail2__table-column_distribution">
                            <col class="unit-detail2__table-column unit-detail2__table-column_distribution">
                        </colgroup>
                        <colgroup>
                            <col class="unit-detail2__table-column unit-detail2__table-column_distribution">
                            <col class="unit-detail2__table-column unit-detail2__table-column_distribution">
                            <col class="unit-detail2__table-column unit-detail2__table-column_distribution">
                        </colgroup>
                        <colgroup>
                            <col class="unit-detail2__table-column unit-detail2__table-column__distribution-delta">
                        </colgroup>
                        <colgroup>
                            <col class="unit-detail2__table-column unit-detail2__table-column_distribution">
                        </colgroup>
                        <colgroup>
                            <col class="unit-detail2__table-column unit-detail2__table-column_distribution">
                        </colgroup>
                        <thead>
                            <tr class="unit-detail2__table-row unit-detail2__table-row_head">
                                <th class="unit-detail2__table-cell unit-detail2__table-cell_vat unit-detail2__table-cell_text-small unit-detail2__table-cell_head unit-detail2__table-cell_sortable"
                                    scope="col"
                                    rowspan="2"
                                    @click="setSorting('distribution', 'title')"
                                >
                                    <span class="unit-detail2__table-text">Источник</span>
                                    <svg-icon class="unit-detail2__table-icon"
                                        v-if="sorting.distribution.field === 'title' && sorting.distribution.direction === 'ASC'"
                                        icon="chevron-up"
                                    />
                                    <svg-icon class="unit-detail2__table-icon"
                                        v-if="sorting.distribution.field === 'title' && sorting.distribution.direction === 'DESC'"
                                        icon="chevron-down"
                                    />
                                </th>
                                <th class="unit-detail2__table-cell unit-detail2__table-cell_vat unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head unit-detail2__table-cell_sortable"
                                    scope="col"
                                    rowspan="2"
                                    @click="setSorting('distribution', 'orders_count')"
                                >
                                    <span class="unit-detail2__table-text">Заказы</span>
                                    <svg-icon class="unit-detail2__table-icon"
                                        v-if="sorting.distribution.field === 'orders_count' && sorting.distribution.direction === 'ASC'"
                                        icon="chevron-up"
                                    />
                                    <svg-icon class="unit-detail2__table-icon"
                                        v-if="sorting.distribution.field === 'orders_count' && sorting.distribution.direction === 'DESC'"
                                        icon="chevron-down"
                                    />
                                </th>
                                <th class="unit-detail2__table-cell unit-detail2__table-cell_vat unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head unit-detail2__table-cell_sortable"
                                    scope="col"
                                    rowspan="2"
                                    @click="setSorting('distribution', 'value')"
                                >
                                    <span class="unit-detail2__table-text">Выручка</span>
                                    <svg-icon class="unit-detail2__table-icon"
                                        v-if="sorting.distribution.field === 'value' && sorting.distribution.direction === 'ASC'"
                                        icon="chevron-up"
                                    />
                                    <svg-icon class="unit-detail2__table-icon"
                                        v-if="sorting.distribution.field === 'value' && sorting.distribution.direction === 'DESC'"
                                        icon="chevron-down"
                                    />
                                </th>
                                <th class="unit-detail2__table-cell unit-detail2__table-cell_vat unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head unit-detail2__table-cell_sortable"
                                    scope="col"
                                    rowspan="2"
                                    @click="setSorting('distribution', 'average_bill')"
                                >
                                    <span class="unit-detail2__table-text">Ср. чек</span>
                                    <svg-icon class="unit-detail2__table-icon"
                                        v-if="sorting.distribution.field === 'average_bill' && sorting.distribution.direction === 'ASC'"
                                        icon="chevron-up"
                                    />
                                    <svg-icon class="unit-detail2__table-icon"
                                        v-if="sorting.distribution.field === 'average_bill' && sorting.distribution.direction === 'DESC'"
                                        icon="chevron-down"
                                    />
                                </th>
                                <th class="unit-detail2__table-cell unit-detail2__table-cell_text-small unit-detail2__table-cell_tac unit-detail2__table-cell_head"
                                    scope="col"
                                    colspan="3"
                                >
                                    <span class="unit-detail2__table-text">Заказы в "+"</span>
                                </th>
                                <th class="unit-detail2__table-cell unit-detail2__table-cell_text-small unit-detail2__table-cell_tac unit-detail2__table-cell_head"
                                    scope="col"
                                    colspan="3"
                                >
                                    <span class="unit-detail2__table-text">Заказы в "-"</span>
                                </th>
                                <th class="unit-detail2__table-cell  unit-detail2__table-cell_vat unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head unit-detail2__table-cell_sortable"
                                    scope="col"
                                    rowspan="2"
                                    @click="setSorting('distribution', 'average_bill_delta')"
                                >
                                    <span class="unit-detail2__table-text">Дельта</span>
                                    <svg-icon class="unit-detail2__table-icon"
                                        v-if="sorting.distribution.field === 'average_bill_delta' && sorting.distribution.direction === 'ASC'"
                                        icon="chevron-up"
                                    />
                                    <svg-icon class="unit-detail2__table-icon"
                                        v-if="sorting.distribution.field === 'average_bill_delta' && sorting.distribution.direction === 'DESC'"
                                        icon="chevron-down"
                                    />
                                </th>
                                <th class="unit-detail2__table-cell  unit-detail2__table-cell_vat unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head unit-detail2__table-cell_sortable"
                                    scope="col"
                                    rowspan="2"
                                    @click="setSorting('distribution', 'value_opex')"
                                >
                                    <span class="unit-detail2__table-text">OPEX</span>
                                    <svg-icon class="unit-detail2__table-icon"
                                        v-if="sorting.distribution.field === 'value_opex' && sorting.distribution.direction === 'ASC'"
                                        icon="chevron-up"
                                    />
                                    <svg-icon class="unit-detail2__table-icon"
                                        v-if="sorting.distribution.field === 'value_opex' && sorting.distribution.direction === 'DESC'"
                                        icon="chevron-down"
                                    />
                                </th>
                                <th class="unit-detail2__table-cell  unit-detail2__table-cell_vat unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head unit-detail2__table-cell_sortable"
                                    scope="col"
                                    rowspan="2"
                                    @click="setSorting('distribution', 'average_opex')"
                                >
                                    <span class="unit-detail2__table-text">Ср.OPEX</span>
                                    <svg-icon class="unit-detail2__table-icon"
                                        v-if="sorting.distribution.field === 'average_opex' && sorting.distribution.direction === 'ASC'"
                                        icon="chevron-up"
                                    />
                                    <svg-icon class="unit-detail2__table-icon"
                                        v-if="sorting.distribution.field === 'average_opex' && sorting.distribution.direction === 'DESC'"
                                        icon="chevron-down"
                                    />
                                </th>
                            </tr>
                            <tr class="unit-detail2__table-row unit-detail2__table-row_head">
                                <th class="unit-detail2__table-cell unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head unit-detail2__table-cell_sortable"
                                    scope="col"
                                    @click="setSorting('distribution', 'orders_count_plus')"
                                >
                                    <span class="unit-detail2__table-text">Количество</span>
                                    <svg-icon class="unit-detail2__table-icon"
                                        v-if="sorting.distribution.field === 'orders_count_plus' && sorting.distribution.direction === 'ASC'"
                                        icon="chevron-up"
                                    />
                                    <svg-icon class="unit-detail2__table-icon"
                                        v-if="sorting.distribution.field === 'orders_count_plus' && sorting.distribution.direction === 'DESC'"
                                        icon="chevron-down"
                                    />
                                </th>
                                <th class="unit-detail2__table-cell unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head unit-detail2__table-cell_sortable"
                                    scope="col"
                                    @click="setSorting('distribution', 'value_plus')"
                                >
                                    <span class="unit-detail2__table-text">Выручка</span>
                                    <svg-icon class="unit-detail2__table-icon"
                                        v-if="sorting.distribution.field === 'value_plus' && sorting.distribution.direction === 'ASC'"
                                        icon="chevron-up"
                                    />
                                    <svg-icon class="unit-detail2__table-icon"
                                        v-if="sorting.distribution.field === 'value_plus' && sorting.distribution.direction === 'DESC'"
                                        icon="chevron-down"
                                    />
                                </th>
                                <th class="unit-detail2__table-cell unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head unit-detail2__table-cell_sortable"
                                    scope="col"
                                    @click="setSorting('distribution', 'average_bill_plus')"
                                >
                                    <span class="unit-detail2__table-text">Ср. чек</span>
                                    <svg-icon class="unit-detail2__table-icon"
                                        v-if="sorting.distribution.field === 'average_bill_plus' && sorting.distribution.direction === 'ASC'"
                                        icon="chevron-up"
                                    />
                                    <svg-icon class="unit-detail2__table-icon"
                                        v-if="sorting.distribution.field === 'average_bill_plus' && sorting.distribution.direction === 'DESC'"
                                        icon="chevron-down"
                                    />
                                </th>
                                <th class="unit-detail2__table-cell unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head unit-detail2__table-cell_sortable"
                                    scope="col"
                                    @click="setSorting('distribution', 'orders_count_minus')"
                                >
                                    <span class="unit-detail2__table-text">Количество</span>
                                    <svg-icon class="unit-detail2__table-icon"
                                        v-if="sorting.distribution.field === 'orders_count_minus' && sorting.distribution.direction === 'ASC'"
                                        icon="chevron-up"
                                    />
                                    <svg-icon class="unit-detail2__table-icon"
                                        v-if="sorting.distribution.field === 'orders_count_minus' && sorting.distribution.direction === 'DESC'"
                                        icon="chevron-down"
                                    />
                                </th>
                                <th class="unit-detail2__table-cell unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head unit-detail2__table-cell_sortable"
                                    scope="col"
                                    @click="setSorting('distribution', 'value_minus')"
                                >
                                    <span class="unit-detail2__table-text">Выручка</span>
                                    <svg-icon class="unit-detail2__table-icon"
                                        v-if="sorting.distribution.field === 'value_minus' && sorting.distribution.direction === 'ASC'"
                                        icon="chevron-up"
                                    />
                                    <svg-icon class="unit-detail2__table-icon"
                                        v-if="sorting.distribution.field === 'value_minus' && sorting.distribution.direction === 'DESC'"
                                        icon="chevron-down"
                                    />
                                </th>
                                <th class="unit-detail2__table-cell unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head unit-detail2__table-cell_sortable"
                                    scope="col"
                                    @click="setSorting('distribution', 'average_bill_minus')"
                                >
                                    <span class="unit-detail2__table-text">Ср. чек</span>
                                    <svg-icon class="unit-detail2__table-icon"
                                        v-if="sorting.distribution.field === 'average_bill_minus' && sorting.distribution.direction === 'ASC'"
                                        icon="chevron-up"
                                    />
                                    <svg-icon class="unit-detail2__table-icon"
                                        v-if="sorting.distribution.field === 'average_bill_minus' && sorting.distribution.direction === 'DESC'"
                                        icon="chevron-down"
                                    />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="unit-detail2__table-row unit-detail2__table-row_body unit-detail2__table-row_pinned">
                                <td class="unit-detail2__table-cell unit-detail2__table-cell_text-medium unit-detail2__table-cell_body unit-detail2__table-cell_title">Итого</td>
                                <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                    {{reportUnit.orders_count | intFormat}}
                                </td>
                                <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                    {{reportUnit.value | currencyIntFormat}}
                                </td>
                                <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                    {{reportUnit.average_bill | currencyIntFormat}}
                                </td>
                                <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                    {{reportUnit.orders_count_plus | intFormat}}
                                </td>
                                <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                    {{reportUnit.value_plus | currencyIntFormat}}
                                </td>
                                <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                    {{reportUnit.average_bill_plus | currencyIntFormat}}
                                </td>
                                <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                    {{reportUnit.orders_count_minus | intFormat}}
                                </td>
                                <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                    {{reportUnit.value_minus | currencyIntFormat}}
                                </td>
                                <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                    {{reportUnit.average_bill_minus | currencyIntFormat}}
                                </td>
                                <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                    {{reportUnit.average_bill_delta | currencyIntFormat}}
                                </td>
                                <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                    {{reportUnit.value_opex | currencyIntFormat}}
                                </td>
                                <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                    {{reportUnit.average_opex | currencyIntFormat}}
                                </td>
                            </tr>
                            <tr class="unit-detail2__table-row unit-detail2__table-row_body unit-detail2__table-row_pinned"
                                v-if="reportAverage"
                            >
                                <td class="unit-detail2__table-cell unit-detail2__table-cell_text-medium unit-detail2__table-cell_body unit-detail2__table-cell_title">Среднее</td>
                                <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                    {{reportAverage.orders_count | intFormat}}
                                </td>
                                <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                    {{reportAverage.value | currencyIntFormat}}
                                </td>
                                <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                    {{reportUnit.average_bill | currencyIntFormat}}
                                </td>
                                <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                    {{reportAverage.orders_count_plus | intFormat}}
                                </td>
                                <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                    {{reportAverage.value_plus | currencyIntFormat}}
                                </td>
                                <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                    {{reportUnit.average_bill_plus | currencyIntFormat}}
                                </td>
                                <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                    {{reportAverage.orders_count_minus | intFormat}}
                                </td>
                                <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                    {{reportAverage.value_minus | currencyIntFormat}}
                                </td>
                                <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                    {{reportUnit.average_bill_minus | currencyIntFormat}}
                                </td>
                                <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                    {{reportUnit.average_bill_delta | currencyIntFormat}}
                                </td>
                                <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                    {{reportAverage.value_opex | currencyIntFormat}}
                                </td>
                                <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                    {{reportUnit.average_opex | currencyIntFormat}}
                                </td>
                            </tr>
                            <tr class="unit-detail2__table-row unit-detail2__table-row_body"
                                v-for="item in reportItems" :key="item.id"
                            >
                                <th class="unit-detail2__table-cell unit-detail2__table-cell_text-medium unit-detail2__table-cell_body" scope="row">
                                    <router-link class="link link_red"
                                        v-if="!isUserWithOneRestaurant"
                                        :to="{ name: 'UnitDetail2', params: { id: item.id } }"
                                        :title='`Смотреть Unit-экономику 2 ${sourceId === "restaurants" ? "ресторана" : "источника"} "${item.title}"`'>
                                        {{item.title}}
                                    </router-link>
                                    <template v-else>{{item.title}}</template>
                                </th>
                                <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                    <span class="unit-detail2__table-value">{{item.orders_count | intFormat}}</span>
                                    <difference class="unit-detail2__table-value"
                                        v-if="reportAverage"
                                        :primaryValue="item.orders_count"
                                        :compareValue="reportAverage.orders_count"
                                        mode="percentage"
                                        filter="percentFloatFormat"
                                    />
                                </td>
                                <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                    <span class="unit-detail2__table-value">{{item.value | currencyIntFormat}}</span>
                                    <difference class="unit-detail2__table-value"
                                        v-if="reportAverage"
                                        :primaryValue="item.value"
                                        :compareValue="reportAverage.value"
                                        mode="percentage"
                                        filter="percentFloatFormat"
                                    />
                                </td>
                                <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                    <span class="unit-detail2__table-value">{{item.average_bill | currencyIntFormat}}</span>
                                    <difference class="unit-detail2__table-value"
                                        :primaryValue="item.average_bill"
                                        :compareValue="reportUnit.average_bill"
                                        mode="subtraction"
                                        filter="currencyIntFormat"
                                    />
                                </td>
                                <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                    <span class="unit-detail2__table-value">{{item.orders_count_plus | intFormat}}</span>
                                    <difference class="unit-detail2__table-value"
                                        v-if="reportAverage"
                                        :primaryValue="item.orders_count_plus"
                                        :compareValue="reportAverage.orders_count_plus"
                                        mode="percentage"
                                        filter="percentFloatFormat"
                                    />
                                </td>
                                <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                    <span class="unit-detail2__table-value">{{item.value_plus | currencyIntFormat}}</span>
                                    <difference class="unit-detail2__table-value"
                                        v-if="reportAverage"
                                        :primaryValue="item.value_plus"
                                        :compareValue="reportAverage.value_plus"
                                        mode="percentage"
                                        filter="percentFloatFormat"
                                    />
                                </td>
                                <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                    <span class="unit-detail2__table-value">{{item.average_bill_plus | currencyIntFormat}}</span>
                                    <difference class="unit-detail2__table-value"
                                        :primaryValue="item.average_bill_plus"
                                        :compareValue="reportUnit.average_bill_plus"
                                        mode="subtraction"
                                        filter="currencyIntFormat"
                                    />
                                </td>
                                <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                    <span class="unit-detail2__table-value">{{item.orders_count_minus | intFormat}}</span>
                                    <difference class="unit-detail2__table-value"
                                        v-if="reportAverage"
                                        :primaryValue="item.orders_count_minus"
                                        :compareValue="reportAverage.orders_count_minus"
                                        mode="percentage"
                                        filter="percentFloatFormat"
                                    />
                                </td>
                                <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                    <span class="unit-detail2__table-value">{{item.value_minus | currencyIntFormat}}</span>
                                    <difference class="unit-detail2__table-value"
                                        v-if="reportAverage"
                                        :primaryValue="item.value_minus"
                                        :compareValue="reportAverage.value_minus"
                                        mode="percentage"
                                        filter="percentFloatFormat"
                                    />
                                </td>
                                <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                    <span class="unit-detail2__table-value">{{item.average_bill_minus | currencyIntFormat}}</span>
                                    <difference class="unit-detail2__table-value"
                                        :primaryValue="item.average_bill_minus"
                                        :compareValue="reportUnit.average_bill_minus"
                                        mode="subtraction"
                                        filter="currencyIntFormat"
                                    />
                                </td>
                                <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                    <span class="unit-detail2__table-value">{{item.average_bill_delta | currencyIntFormat}}</span>
                                    <difference class="unit-detail2__table-value"
                                        :primaryValue="item.average_bill_delta"
                                        :compareValue="reportUnit.average_bill_delta"
                                        mode="subtraction"
                                        filter="currencyIntFormat"
                                    />
                                </td>
                                <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                    <span class="unit-detail2__table-value">{{item.value_opex | currencyIntFormat}}</span>
                                    <difference class="unit-detail2__table-value"
                                        v-if="reportAverage"
                                        :primaryValue="item.value_opex"
                                        :compareValue="reportAverage.value_opex"
                                        mode="percentage"
                                        filter="percentFloatFormat"
                                    />
                                </td>
                                <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                    <span class="unit-detail2__table-value">{{item.average_opex | currencyIntFormat}}</span>
                                    <difference class="unit-detail2__table-value"
                                        :primaryValue="item.average_opex"
                                        :compareValue="reportUnit.average_opex"
                                        mode="subtraction"
                                        filter="currencyIntFormat"
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="unit-detail2__base-distribution-chart"
                v-else-if="modeId === 'base' && sourceId === 'distribution' && viewId === 'chart'"
            >
                <div class="unit-detail2__box unit-detail2__box_chart box">
                    <highcharts class="unit-detail2__chart" :options="unitItemsColumnChartOptions" :key="'unitItemsBaseColumnChart'" />
                </div>
                <div class="unit-detail2__box unit-detail2__box_chart box"
                    v-if="!chartFieldId.startsWith('average_bill')"
                >
                    <highcharts class="unit-detail2__chart" :options="unitItemsPieChartOptions(reportItems, 'Период отчета')" :key="'unitItemsBasePieChart'" />
                </div>
            </div>
            <div class="unit-detail2__base-orders" v-else-if="modeId === 'base' && sourceId === 'orders'">
                <div class="unit-detail2__box unit-detail2__box_orders box"
                    :class="{ 'unit-detail2__box_no-overflow': isOrdersPageLoading }"
                    ref="ordersBox"
                    :style="{ 'max-height': `${ordersBoxMaxHeight}px` }"
                >
                    <div class="unit-detail2__orders-table-wrapper">
                        <table class="unit-detail2__table unit-detail2__table_orders unit-detail2__table_striped">
                            <caption class="unit-detail2__table-caption" v-if="ordersTotalCount">
                                Всего заказов: {{ordersTotalCount | intFormat}}
                            </caption>
                            <col class="unit-detail2__table-column unit-detail2__table-column_order-date">
                            <col class="unit-detail2__table-column unit-detail2__table-column_order-orderid">
                            <col class="unit-detail2__table-column unit-detail2__table-column_order-num">
                            <col class="unit-detail2__table-column unit-detail2__table-column_order-department"
                                v-if="type === 'origins' || type === 'total'"
                            >
                            <col class="unit-detail2__table-column unit-detail2__table-column_order-origin"
                                v-if="type === 'restaurants' || type === 'total'"
                            >
                            <col class="unit-detail2__table-column unit-detail2__table-column_order-type">
                            <col class="unit-detail2__table-column unit-detail2__table-column_order-order_type">
                            <col class="unit-detail2__table-column unit-detail2__table-column_order-value">
                            <col class="unit-detail2__table-column unit-detail2__table-column_order-first">
                            <col class="unit-detail2__table-column unit-detail2__table-column_order-guests">
                            <col class="unit-detail2__table-column unit-detail2__table-column_order-items">
                            <col class="unit-detail2__table-column unit-detail2__table-column_order-comission">
                            <col class="unit-detail2__table-column unit-detail2__table-column_order-call-center">
                            <col class="unit-detail2__table-column unit-detail2__table-column_order-fuel">
                            <col class="unit-detail2__table-column unit-detail2__table-column_order-rent">
                            <col class="unit-detail2__table-column unit-detail2__table-column_order-utilities">
                            <template v-if="showWageGroups">
                                <col class="unit-detail2__table-column unit-detail2__table-column_order-wage-change-group1-value">
                                <col class="unit-detail2__table-column unit-detail2__table-column_order-wage-change-group2-value">
                                <col class="unit-detail2__table-column unit-detail2__table-column_order-wage-change-group3-value">
                                <col class="unit-detail2__table-column unit-detail2__table-column_order-wage-change-group4-value">
                                <col class="unit-detail2__table-column unit-detail2__table-column_order-wage-change-group5-value">
                                <col class="unit-detail2__table-column unit-detail2__table-column_order-wage-change-group6-value">
                                <col class="unit-detail2__table-column unit-detail2__table-column_order-wage-change-group7-value">
                                <col class="unit-detail2__table-column unit-detail2__table-column_order-wage-change-group8-value">
                                <col class="unit-detail2__table-column unit-detail2__table-column_order-wage-fixed-group1-value">
                                <col class="unit-detail2__table-column unit-detail2__table-column_order-wage-fixed-group2-value">
                                <col class="unit-detail2__table-column unit-detail2__table-column_order-wage-fixed-group3-value">
                                <col class="unit-detail2__table-column unit-detail2__table-column_order-wage-fixed-group4-value">
                                <col class="unit-detail2__table-column unit-detail2__table-column_order-wage-fixed-group5-value">
                                <col class="unit-detail2__table-column unit-detail2__table-column_order-wage-fixed-group6-value">
                                <col class="unit-detail2__table-column unit-detail2__table-column_order-wage-fixed-group7-value">
                                <col class="unit-detail2__table-column unit-detail2__table-column_order-wage-fixed-group8-value">
                            </template>
                            <col class="unit-detail2__table-column unit-detail2__table-column_order-wage-change">
                            <col class="unit-detail2__table-column unit-detail2__table-column_order-wage-fixed">
                            <col class="unit-detail2__table-column unit-detail2__table-column_order-opex">
                            <col class="unit-detail2__table-column unit-detail2__table-column_order-result">
                            <col class="unit-detail2__table-column unit-detail2__table-column_order-is-profit">
                            <thead>
                                <tr class="unit-detail2__table-row unit-detail2__table-row_head">
                                    <th class="unit-detail2__table-cell unit-detail2__table-cell_vat unit-detail2__table-cell_text-small unit-detail2__table-cell_head unit-detail2__table-cell_sortable"
                                        scope="col"
                                        @click="setSorting('orders', 'date')"
                                    >
                                        <div class="unit-detail2__table-cell-row">
                                            <span class="unit-detail2__table-text">Дата</span>
                                            <svg-icon class="unit-detail2__table-icon" v-if="sorting.orders.field === 'date'"
                                                :icon="`chevron-${sorting.orders.direction === 'ASC' ? 'up' : 'down'}`"
                                            />
                                        </div>
                                    </th>
                                    <th class="unit-detail2__table-cell unit-detail2__table-cell_vat unit-detail2__table-cell_text-small unit-detail2__table-cell_head unit-detail2__table-cell_sortable"
                                        scope="col"
                                        @click="setSorting('orders', 'orderid')"
                                    >
                                        <div class="unit-detail2__table-cell-row">
                                            <span class="unit-detail2__table-text">Id заказа</span>
                                            <svg-icon class="unit-detail2__table-icon" v-if="sorting.orders.field === 'orderid'"
                                                :icon="`chevron-${sorting.orders.direction === 'ASC' ? 'up' : 'down'}`"
                                            />
                                        </div>
                                    </th>
                                    <th class="unit-detail2__table-cell unit-detail2__table-cell_num unit-detail2__table-cell_vat unit-detail2__table-cell_text-small unit-detail2__table-cell_head unit-detail2__table-cell_sortable"
                                        scope="col"
                                        @click="setSorting('orders', 'num')"
                                    >
                                        <div class="unit-detail2__table-cell-row">
                                            <span class="unit-detail2__table-text">Номер заказа</span>
                                            <svg-icon class="unit-detail2__table-icon" v-if="sorting.orders.field === 'num'"
                                                :icon="`chevron-${sorting.orders.direction === 'ASC' ? 'up' : 'down'}`"
                                            />
                                        </div>
                                    </th>
                                    <th class="unit-detail2__table-cell unit-detail2__table-cell_vat unit-detail2__table-cell_text-small unit-detail2__table-cell_head unit-detail2__table-cell_sortable"
                                        v-if="type === 'origins' || type === 'total'"
                                        scope="col"
                                        @click="setSorting('orders', 'department')"
                                    >
                                        <div class="unit-detail2__table-cell-row">
                                            <span class="unit-detail2__table-text">Ресторан</span>
                                            <svg-icon class="unit-detail2__table-icon" v-if="sorting.orders.field === 'department'"
                                                :icon="`chevron-${sorting.orders.direction === 'ASC' ? 'up' : 'down'}`"
                                            />
                                        </div>
                                    </th>
                                    <th class="unit-detail2__table-cell unit-detail2__table-cell_vat unit-detail2__table-cell_text-small unit-detail2__table-cell_head unit-detail2__table-cell_sortable"
                                        v-if="type === 'restaurants' || type === 'total'"
                                        scope="col"
                                        @click="setSorting('orders', 'origin')"
                                    >
                                        <div class="unit-detail2__table-cell-row">
                                            <span class="unit-detail2__table-text">Источник</span>
                                            <svg-icon class="unit-detail2__table-icon" v-if="sorting.orders.field === 'origin'"
                                                :icon="`chevron-${sorting.orders.direction === 'ASC' ? 'up' : 'down'}`"
                                            />
                                        </div>
                                    </th>
                                    <th class="unit-detail2__table-cell unit-detail2__table-cell_vat unit-detail2__table-cell_text-small unit-detail2__table-cell_head unit-detail2__table-cell_sortable"
                                        scope="col"
                                        @click="setSorting('orders', 'type')"
                                    >
                                        <div class="unit-detail2__table-cell-row">
                                            <span class="unit-detail2__table-text">Тип обслуживания</span>
                                            <svg-icon class="unit-detail2__table-icon" v-if="sorting.orders.field === 'type'"
                                                :icon="`chevron-${sorting.orders.direction === 'ASC' ? 'up' : 'down'}`"
                                            />
                                        </div>
                                    </th>
                                    <th class="unit-detail2__table-cell unit-detail2__table-cell_vat unit-detail2__table-cell_text-small unit-detail2__table-cell_head unit-detail2__table-cell_sortable"
                                        scope="col"
                                        @click="setSorting('orders', 'order_type')"
                                    >
                                        <div class="unit-detail2__table-cell-row">
                                            <span class="unit-detail2__table-text">Тип заказа</span>
                                            <svg-icon class="unit-detail2__table-icon" v-if="sorting.orders.field === 'order_type'"
                                                :icon="`chevron-${sorting.orders.direction === 'ASC' ? 'up' : 'down'}`"
                                            />
                                        </div>
                                    </th>
                                    <th class="unit-detail2__table-cell  unit-detail2__table-cell_vat unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head unit-detail2__table-cell_sortable"
                                        scope="col"
                                        @click="setSorting('orders', 'value')"
                                    >
                                        <div class="unit-detail2__table-cell-row">
                                            <span class="unit-detail2__table-text">Сумма<br> со скидкой</span>
                                            <svg-icon class="unit-detail2__table-icon" v-if="sorting.orders.field === 'value'"
                                                :icon="`chevron-${sorting.orders.direction === 'ASC' ? 'up' : 'down'}`"
                                            />
                                        </div>
                                    </th>
                                    <th class="unit-detail2__table-cell  unit-detail2__table-cell_vat unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head unit-detail2__table-cell_sortable"
                                        scope="col"
                                        @click="setSorting('orders', 'first')"
                                    >
                                        <div class="unit-detail2__table-cell-row">
                                            <span class="unit-detail2__table-text">Себестоимость</span>
                                            <svg-icon class="unit-detail2__table-icon" v-if="sorting.orders.field === 'first'"
                                                :icon="`chevron-${sorting.orders.direction === 'ASC' ? 'up' : 'down'}`"
                                            />
                                        </div>
                                    </th>
                                    <th class="unit-detail2__table-cell  unit-detail2__table-cell_vat unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head unit-detail2__table-cell_sortable"
                                        scope="col"
                                        @click="setSorting('orders', 'guests')"
                                    >
                                        <div class="unit-detail2__table-cell-row">
                                            <span class="unit-detail2__table-text">Гости</span>
                                            <svg-icon class="unit-detail2__table-icon" v-if="sorting.orders.field === 'guests'"
                                                :icon="`chevron-${sorting.orders.direction === 'ASC' ? 'up' : 'down'}`"
                                            />
                                        </div>
                                    </th>
                                    <th class="unit-detail2__table-cell  unit-detail2__table-cell_vat unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head unit-detail2__table-cell_sortable"
                                        scope="col"
                                        @click="setSorting('orders', 'items')"
                                    >
                                        <div class="unit-detail2__table-cell-row">
                                            <span class="unit-detail2__table-text">Блюда</span>
                                            <svg-icon class="unit-detail2__table-icon" v-if="sorting.orders.field === 'items'"
                                                :icon="`chevron-${sorting.orders.direction === 'ASC' ? 'up' : 'down'}`"
                                            />
                                        </div>
                                    </th>
                                    <th class="unit-detail2__table-cell  unit-detail2__table-cell_vat unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head unit-detail2__table-cell_sortable"
                                        scope="col"
                                        @click="setSorting('orders', 'comission')"
                                    >
                                        <div class="unit-detail2__table-cell-row">
                                            <span class="unit-detail2__table-text">Комиссия<br> служб доставки</span>
                                            <svg-icon class="unit-detail2__table-icon" v-if="sorting.orders.field === 'comission'"
                                                :icon="`chevron-${sorting.orders.direction === 'ASC' ? 'up' : 'down'}`"
                                            />
                                        </div>
                                    </th>
                                    <th class="unit-detail2__table-cell  unit-detail2__table-cell_vat unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head unit-detail2__table-cell_sortable"
                                        scope="col"
                                        @click="setSorting('orders', 'call_center')"
                                    >
                                        <div class="unit-detail2__table-cell-row">
                                            <span class="unit-detail2__table-text">Комиссия<br> колл-центра</span>
                                            <svg-icon class="unit-detail2__table-icon" v-if="sorting.orders.field === 'call_center'"
                                                :icon="`chevron-${sorting.orders.direction === 'ASC' ? 'up' : 'down'}`"
                                            />
                                        </div>
                                    </th>
                                    <th class="unit-detail2__table-cell  unit-detail2__table-cell_vat unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head unit-detail2__table-cell_sortable"
                                        scope="col"
                                        @click="setSorting('orders', 'fuel')"
                                    >
                                        <div class="unit-detail2__table-cell-row">
                                            <span class="unit-detail2__table-text">ГСМ</span>
                                            <svg-icon class="unit-detail2__table-icon" v-if="sorting.orders.field === 'fuel'"
                                                :icon="`chevron-${sorting.orders.direction === 'ASC' ? 'up' : 'down'}`"
                                            />
                                        </div>
                                    </th>
                                    <th class="unit-detail2__table-cell  unit-detail2__table-cell_vat unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head unit-detail2__table-cell_sortable"
                                        scope="col"
                                        @click="setSorting('orders', 'rent')"
                                    >
                                        <div class="unit-detail2__table-cell-row">
                                            <span class="unit-detail2__table-text">Аренда</span>
                                            <svg-icon class="unit-detail2__table-icon" v-if="sorting.orders.field === 'rent'"
                                                :icon="`chevron-${sorting.orders.direction === 'ASC' ? 'up' : 'down'}`"
                                            />
                                        </div>
                                    </th>
                                    <th class="unit-detail2__table-cell  unit-detail2__table-cell_vat unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head unit-detail2__table-cell_sortable"
                                        scope="col"
                                        @click="setSorting('orders', 'utilities')"
                                    >
                                        <div class="unit-detail2__table-cell-row">
                                            <span class="unit-detail2__table-text">Коммуналка</span>
                                            <svg-icon class="unit-detail2__table-icon" v-if="sorting.orders.field === 'utilities'"
                                                :icon="`chevron-${sorting.orders.direction === 'ASC' ? 'up' : 'down'}`"
                                            />
                                        </div>
                                    </th>
                                    <th class="unit-detail2__table-cell  unit-detail2__table-cell_vat unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head unit-detail2__table-cell_sortable"
                                        scope="col"
                                        @click="setSorting('orders', 'marketing_federal')"
                                    >
                                        <div class="unit-detail2__table-cell-row">
                                            <span class="unit-detail2__table-text">Федеральный<br> маркетинг</span>
                                            <svg-icon class="unit-detail2__table-icon" v-if="sorting.orders.field === 'marketing_federal'"
                                                :icon="`chevron-${sorting.orders.direction === 'ASC' ? 'up' : 'down'}`"
                                            />
                                        </div>
                                    </th>
                                    <th class="unit-detail2__table-cell  unit-detail2__table-cell_vat unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head unit-detail2__table-cell_sortable"
                                        scope="col"
                                        @click="setSorting('orders', 'marketing_local')"
                                    >
                                        <div class="unit-detail2__table-cell-row">
                                            <span class="unit-detail2__table-text">Локальный<br> маркетинг</span>
                                            <svg-icon class="unit-detail2__table-icon" v-if="sorting.orders.field === 'marketing_local'"
                                                :icon="`chevron-${sorting.orders.direction === 'ASC' ? 'up' : 'down'}`"
                                            />
                                        </div>
                                    </th>
                                    <template v-if="showWageGroups">
                                        <th class="unit-detail2__table-cell  unit-detail2__table-cell_vat unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head unit-detail2__table-cell_sortable"
                                            scope="col"
                                            @click="setSorting('orders', 'wage_change_group1_value')"
                                        >
                                            <div class="unit-detail2__table-cell-row">
                                                <span class="unit-detail2__table-text">ФОТ переменный<br> Курьерская служба</span>
                                                <svg-icon class="unit-detail2__table-icon" v-if="sorting.orders.field === 'wage_change_group1_value'"
                                                    :icon="`chevron-${sorting.orders.direction === 'ASC' ? 'up' : 'down'}`"
                                                />
                                            </div>
                                        </th>
                                        <th class="unit-detail2__table-cell  unit-detail2__table-cell_vat unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head unit-detail2__table-cell_sortable"
                                            scope="col"
                                            @click="setSorting('orders', 'wage_change_group2_value')"
                                        >
                                            <div class="unit-detail2__table-cell-row">
                                                <span class="unit-detail2__table-text">ФОТ переменный<br> Менеджер доставки</span>
                                                <svg-icon class="unit-detail2__table-icon" v-if="sorting.orders.field === 'wage_change_group2_value'"
                                                    :icon="`chevron-${sorting.orders.direction === 'ASC' ? 'up' : 'down'}`"
                                                />
                                            </div>
                                        </th>
                                        <th class="unit-detail2__table-cell  unit-detail2__table-cell_vat unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head unit-detail2__table-cell_sortable"
                                            scope="col"
                                            @click="setSorting('orders', 'wage_change_group3_value')"
                                        >
                                            <div class="unit-detail2__table-cell-row">
                                                <span class="unit-detail2__table-text">ФОТ переменный<br> Кухня</span>
                                                <svg-icon class="unit-detail2__table-icon" v-if="sorting.orders.field === 'wage_change_group3_value'"
                                                    :icon="`chevron-${sorting.orders.direction === 'ASC' ? 'up' : 'down'}`"
                                                />
                                            </div>
                                        </th>
                                        <th class="unit-detail2__table-cell  unit-detail2__table-cell_vat unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head unit-detail2__table-cell_sortable"
                                            scope="col"
                                            @click="setSorting('orders', 'wage_change_group4_value')"
                                        >
                                            <div class="unit-detail2__table-cell-row">
                                                <span class="unit-detail2__table-text">ФОТ переменный<br> Официанты</span>
                                                <svg-icon class="unit-detail2__table-icon" v-if="sorting.orders.field === 'wage_change_group4_value'"
                                                    :icon="`chevron-${sorting.orders.direction === 'ASC' ? 'up' : 'down'}`"
                                                />
                                            </div>
                                        </th>
                                        <th class="unit-detail2__table-cell  unit-detail2__table-cell_vat unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head unit-detail2__table-cell_sortable"
                                            scope="col"
                                            @click="setSorting('orders', 'wage_change_group5_value')"
                                        >
                                            <div class="unit-detail2__table-cell-row">
                                                <span class="unit-detail2__table-text">ФОТ переменный<br> Управляющие</span>
                                                <svg-icon class="unit-detail2__table-icon" v-if="sorting.orders.field === 'wage_change_group5_value'"
                                                    :icon="`chevron-${sorting.orders.direction === 'ASC' ? 'up' : 'down'}`"
                                                />
                                            </div>
                                        </th>
                                        <th class="unit-detail2__table-cell  unit-detail2__table-cell_vat unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head unit-detail2__table-cell_sortable"
                                            scope="col"
                                            @click="setSorting('orders', 'wage_change_group6_value')"
                                        >
                                            <div class="unit-detail2__table-cell-row">
                                                <span class="unit-detail2__table-text">ФОТ переменный<br> Бармен</span>
                                                <svg-icon class="unit-detail2__table-icon" v-if="sorting.orders.field === 'wage_change_group6_value'"
                                                    :icon="`chevron-${sorting.orders.direction === 'ASC' ? 'up' : 'down'}`"
                                                />
                                            </div>
                                        </th>
                                        <th class="unit-detail2__table-cell  unit-detail2__table-cell_vat unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head unit-detail2__table-cell_sortable"
                                            scope="col"
                                            @click="setSorting('orders', 'wage_change_group7_value')"
                                        >
                                            <div class="unit-detail2__table-cell-row">
                                                <span class="unit-detail2__table-text">ФОТ переменный<br> Администратор</span>
                                                <svg-icon class="unit-detail2__table-icon" v-if="sorting.orders.field === 'wage_change_group7_value'"
                                                    :icon="`chevron-${sorting.orders.direction === 'ASC' ? 'up' : 'down'}`"
                                                />
                                            </div>
                                        </th>
                                        <th class="unit-detail2__table-cell  unit-detail2__table-cell_vat unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head unit-detail2__table-cell_sortable"
                                            scope="col"
                                            @click="setSorting('orders', 'wage_change_group8_value')"
                                        >
                                            <div class="unit-detail2__table-cell-row">
                                                <span class="unit-detail2__table-text">ФОТ переменный<br> Тех. персонал</span>
                                                <svg-icon class="unit-detail2__table-icon" v-if="sorting.orders.field === 'wage_change_group8_value'"
                                                    :icon="`chevron-${sorting.orders.direction === 'ASC' ? 'up' : 'down'}`"
                                                />
                                            </div>
                                        </th>
                                        <th class="unit-detail2__table-cell  unit-detail2__table-cell_vat unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head unit-detail2__table-cell_sortable"
                                            scope="col"
                                            @click="setSorting('orders', 'wage_fixed_group1_value')"
                                        >
                                            <div class="unit-detail2__table-cell-row">
                                                <span class="unit-detail2__table-text">ФОТ постоянный<br> Курьерская служба</span>
                                                <svg-icon class="unit-detail2__table-icon" v-if="sorting.orders.field === 'wage_fixed_group1_value'"
                                                    :icon="`chevron-${sorting.orders.direction === 'ASC' ? 'up' : 'down'}`"
                                                />
                                            </div>
                                        </th>
                                        <th class="unit-detail2__table-cell  unit-detail2__table-cell_vat unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head unit-detail2__table-cell_sortable"
                                            scope="col"
                                            @click="setSorting('orders', 'wage_fixed_group2_value')"
                                        >
                                            <div class="unit-detail2__table-cell-row">
                                                <span class="unit-detail2__table-text">ФОТ постоянный<br> Менеджер доставки</span>
                                                <svg-icon class="unit-detail2__table-icon" v-if="sorting.orders.field === 'wage_fixed_group2_value'"
                                                    :icon="`chevron-${sorting.orders.direction === 'ASC' ? 'up' : 'down'}`"
                                                />
                                            </div>
                                        </th>
                                        <th class="unit-detail2__table-cell  unit-detail2__table-cell_vat unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head unit-detail2__table-cell_sortable"
                                            scope="col"
                                            @click="setSorting('orders', 'wage_fixed_group3_value')"
                                        >
                                            <div class="unit-detail2__table-cell-row">
                                                <span class="unit-detail2__table-text">ФОТ постоянный<br> Кухня</span>
                                                <svg-icon class="unit-detail2__table-icon" v-if="sorting.orders.field === 'wage_fixed_group3_value'"
                                                    :icon="`chevron-${sorting.orders.direction === 'ASC' ? 'up' : 'down'}`"
                                                />
                                            </div>
                                        </th>
                                        <th class="unit-detail2__table-cell  unit-detail2__table-cell_vat unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head unit-detail2__table-cell_sortable"
                                            scope="col"
                                            @click="setSorting('orders', 'wage_fixed_group4_value')"
                                        >
                                            <div class="unit-detail2__table-cell-row">
                                                <span class="unit-detail2__table-text">ФОТ постоянный<br> Официанты</span>
                                                <svg-icon class="unit-detail2__table-icon" v-if="sorting.orders.field === 'wage_fixed_group4_value'"
                                                    :icon="`chevron-${sorting.orders.direction === 'ASC' ? 'up' : 'down'}`"
                                                />
                                            </div>
                                        </th>
                                        <th class="unit-detail2__table-cell  unit-detail2__table-cell_vat unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head unit-detail2__table-cell_sortable"
                                            scope="col"
                                            @click="setSorting('orders', 'wage_fixed_group5_value')"
                                        >
                                            <div class="unit-detail2__table-cell-row">
                                                <span class="unit-detail2__table-text">ФОТ постоянный<br> Управляющие</span>
                                                <svg-icon class="unit-detail2__table-icon" v-if="sorting.orders.field === 'wage_fixed_group5_value'"
                                                    :icon="`chevron-${sorting.orders.direction === 'ASC' ? 'up' : 'down'}`"
                                                />
                                            </div>
                                        </th>
                                        <th class="unit-detail2__table-cell  unit-detail2__table-cell_vat unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head unit-detail2__table-cell_sortable"
                                            scope="col"
                                            @click="setSorting('orders', 'wage_fixed_group6_value')"
                                        >
                                            <div class="unit-detail2__table-cell-row">
                                                <span class="unit-detail2__table-text">ФОТ постоянный<br> Бармен</span>
                                                <svg-icon class="unit-detail2__table-icon" v-if="sorting.orders.field === 'wage_fixed_group6_value'"
                                                    :icon="`chevron-${sorting.orders.direction === 'ASC' ? 'up' : 'down'}`"
                                                />
                                            </div>
                                        </th>
                                        <th class="unit-detail2__table-cell  unit-detail2__table-cell_vat unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head unit-detail2__table-cell_sortable"
                                            scope="col"
                                            @click="setSorting('orders', 'wage_fixed_group7_value')"
                                        >
                                            <div class="unit-detail2__table-cell-row">
                                                <span class="unit-detail2__table-text">ФОТ постоянный<br> Администратор</span>
                                                <svg-icon class="unit-detail2__table-icon" v-if="sorting.orders.field === 'wage_fixed_group7_value'"
                                                    :icon="`chevron-${sorting.orders.direction === 'ASC' ? 'up' : 'down'}`"
                                                />
                                            </div>
                                        </th>
                                        <th class="unit-detail2__table-cell  unit-detail2__table-cell_vat unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head unit-detail2__table-cell_sortable"
                                            scope="col"
                                            @click="setSorting('orders', 'wage_fixed_group8_value')"
                                        >
                                            <div class="unit-detail2__table-cell-row">
                                                <span class="unit-detail2__table-text">ФОТ постоянный<br> Тех. персонал</span>
                                                <svg-icon class="unit-detail2__table-icon" v-if="sorting.orders.field === 'wage_fixed_group8_value'"
                                                    :icon="`chevron-${sorting.orders.direction === 'ASC' ? 'up' : 'down'}`"
                                                />
                                            </div>
                                        </th>
                                    </template>
                                    <th class="unit-detail2__table-cell  unit-detail2__table-cell_vat unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head unit-detail2__table-cell_sortable"
                                        scope="col"
                                        @click="setSorting('orders', 'wage_change')"
                                    >
                                        <div class="unit-detail2__table-cell-row">
                                            <span class="unit-detail2__table-text">ФОТ переменный<br> - сумма</span>
                                            <svg-icon class="unit-detail2__table-icon" v-if="sorting.orders.field === 'wage_change'"
                                                :icon="`chevron-${sorting.orders.direction === 'ASC' ? 'up' : 'down'}`"
                                            />
                                        </div>
                                    </th>
                                    <th class="unit-detail2__table-cell  unit-detail2__table-cell_vat unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head unit-detail2__table-cell_sortable"
                                        scope="col"
                                        @click="setSorting('orders', 'wage_fixed')"
                                    >
                                        <div class="unit-detail2__table-cell-row">
                                            <span class="unit-detail2__table-text">ФОТ постоянный<br> - сумма</span>
                                            <svg-icon class="unit-detail2__table-icon" v-if="sorting.orders.field === 'wage_fixed'"
                                                :icon="`chevron-${sorting.orders.direction === 'ASC' ? 'up' : 'down'}`"
                                            />
                                        </div>
                                    </th>
                                    <th class="unit-detail2__table-cell  unit-detail2__table-cell_vat unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head unit-detail2__table-cell_sortable"
                                        scope="col"
                                        @click="setSorting('orders', 'opex')"
                                    >
                                        <div class="unit-detail2__table-cell-row">
                                            <span class="unit-detail2__table-text">OPEX</span>
                                            <svg-icon class="unit-detail2__table-icon" v-if="sorting.orders.field === 'opex'"
                                                :icon="`chevron-${sorting.orders.direction === 'ASC' ? 'up' : 'down'}`"
                                            />
                                        </div>
                                    </th>
                                    <th class="unit-detail2__table-cell  unit-detail2__table-cell_vat unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head unit-detail2__table-cell_sortable"
                                        scope="col"
                                        @click="setSorting('orders', 'result')"
                                    >
                                        <div class="unit-detail2__table-cell-row">
                                            <span class="unit-detail2__table-text">Итог</span>
                                            <svg-icon class="unit-detail2__table-icon" v-if="sorting.orders.field === 'result'"
                                                :icon="`chevron-${sorting.orders.direction === 'ASC' ? 'up' : 'down'}`"
                                            />
                                        </div>
                                    </th>
                                    <th class="unit-detail2__table-cell  unit-detail2__table-cell_vat unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head unit-detail2__table-cell_sortable"
                                        scope="col"
                                        @click="setSorting('orders', 'is_profit')"
                                    >
                                        <div class="unit-detail2__table-cell-row">
                                            <span class="unit-detail2__table-text">>0</span>
                                            <svg-icon class="unit-detail2__table-icon" v-if="sorting.orders.field === 'is_profit'"
                                                :icon="`chevron-${sorting.orders.direction === 'ASC' ? 'up' : 'down'}`"
                                            />
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="unit-detail2__table-row unit-detail2__table-row_body"
                                    v-for="order in orders" :key="order.id"
                                >
                                    <td class="unit-detail2__table-cell unit-detail2__table-cell_body unit-detail2__table-cell_text-medium">
                                        <span class="unit-detail2__table-value">{{order.date | dateText}}</span>
                                    </td>
                                    <td class="unit-detail2__table-cell unit-detail2__table-cell_body unit-detail2__table-cell_text-medium">
                                        <span class="unit-detail2__table-value">{{order.orderid}}</span>
                                    </td>
                                    <td class="unit-detail2__table-cell unit-detail2__table-cell_num unit-detail2__table-cell_body unit-detail2__table-cell_text-medium">
                                        <span class="unit-detail2__table-value">{{order.num}}</span>
                                    </td>
                                    <td class="unit-detail2__table-cell unit-detail2__table-cell_body unit-detail2__table-cell_text-medium"
                                        v-if="type === 'origins' || type === 'total'"
                                    >
                                        <span class="unit-detail2__table-value">{{order.department}}</span>
                                    </td>
                                    <td class="unit-detail2__table-cell unit-detail2__table-cell_body unit-detail2__table-cell_text-medium"
                                        v-if="type === 'restaurants' || type === 'total'"
                                    >
                                        <span class="unit-detail2__table-value">{{order.origin}}</span>
                                    </td>
                                    <td class="unit-detail2__table-cell unit-detail2__table-cell_body unit-detail2__table-cell_text-medium">
                                        <span class="unit-detail2__table-value">{{order.type}}</span>
                                    </td>
                                    <td class="unit-detail2__table-cell unit-detail2__table-cell_body unit-detail2__table-cell_text-medium">
                                        <span class="unit-detail2__table-value">{{order.order_type}}</span>
                                    </td>
                                    <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body unit-detail2__table-cell_text-medium">
                                        <span class="unit-detail2__table-value">{{order.value | currencyIntFormat}}</span>
                                    </td>
                                    <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body unit-detail2__table-cell_text-medium">
                                        <span class="unit-detail2__table-value">{{order.first | currencyFloatFormat}}</span>
                                    </td>
                                    <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body unit-detail2__table-cell_text-medium">
                                        <span class="unit-detail2__table-value">{{order.guests | intFormat}}</span>
                                    </td>
                                    <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body unit-detail2__table-cell_text-medium">
                                        <span class="unit-detail2__table-value">{{order.items | intFormat}}</span>
                                    </td>
                                    <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body unit-detail2__table-cell_text-medium">
                                        <span class="unit-detail2__table-value">{{order.comission | currencyFloatFormat}}</span>
                                    </td>
                                    <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body unit-detail2__table-cell_text-medium">
                                        <span class="unit-detail2__table-value">{{order.call_center | currencyFloatFormat}}</span>
                                    </td>
                                    <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body unit-detail2__table-cell_text-medium">
                                        <span class="unit-detail2__table-value">{{order.fuel | currencyFloatFormat}}</span>
                                    </td>
                                    <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body unit-detail2__table-cell_text-medium">
                                        <span class="unit-detail2__table-value">{{order.rent | currencyFloatFormat}}</span>
                                    </td>
                                    <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body unit-detail2__table-cell_text-medium">
                                        <span class="unit-detail2__table-value">{{order.utilities | currencyFloatFormat}}</span>
                                    </td>
                                    <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body unit-detail2__table-cell_text-medium">
                                        <span class="unit-detail2__table-value">{{order.marketing_federal | currencyFloatFormat}}</span>
                                    </td>
                                    <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body unit-detail2__table-cell_text-medium">
                                        <span class="unit-detail2__table-value">{{order.marketing_local | currencyFloatFormat}}</span>
                                    </td>
                                    <template v-if="showWageGroups">
                                        <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body unit-detail2__table-cell_text-medium">
                                            <span class="unit-detail2__table-value">{{order.wage_change_group1_value | currencyFloatFormat}}</span>
                                        </td>
                                        <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body unit-detail2__table-cell_text-medium">
                                            <span class="unit-detail2__table-value">{{order.wage_change_group2_value | currencyFloatFormat}}</span>
                                        </td>
                                        <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body unit-detail2__table-cell_text-medium">
                                            <span class="unit-detail2__table-value">{{order.wage_change_group3_value | currencyFloatFormat}}</span>
                                        </td>
                                        <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body unit-detail2__table-cell_text-medium">
                                            <span class="unit-detail2__table-value">{{order.wage_change_group4_value | currencyFloatFormat}}</span>
                                        </td>
                                        <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body unit-detail2__table-cell_text-medium">
                                            <span class="unit-detail2__table-value">{{order.wage_change_group5_value | currencyFloatFormat}}</span>
                                        </td>
                                        <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body unit-detail2__table-cell_text-medium">
                                            <span class="unit-detail2__table-value">{{order.wage_change_group6_value | currencyFloatFormat}}</span>
                                        </td>
                                        <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body unit-detail2__table-cell_text-medium">
                                            <span class="unit-detail2__table-value">{{order.wage_change_group7_value | currencyFloatFormat}}</span>
                                        </td>
                                        <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body unit-detail2__table-cell_text-medium">
                                            <span class="unit-detail2__table-value">{{order.wage_change_group8_value | currencyFloatFormat}}</span>
                                        </td>
                                        <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body unit-detail2__table-cell_text-medium">
                                            <span class="unit-detail2__table-value">{{order.wage_fixed_group1_value | currencyFloatFormat}}</span>
                                        </td>
                                        <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body unit-detail2__table-cell_text-medium">
                                            <span class="unit-detail2__table-value">{{order.wage_fixed_group2_value | currencyFloatFormat}}</span>
                                        </td>
                                        <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body unit-detail2__table-cell_text-medium">
                                            <span class="unit-detail2__table-value">{{order.wage_fixed_group3_value | currencyFloatFormat}}</span>
                                        </td>
                                        <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body unit-detail2__table-cell_text-medium">
                                            <span class="unit-detail2__table-value">{{order.wage_fixed_group4_value | currencyFloatFormat}}</span>
                                        </td>
                                        <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body unit-detail2__table-cell_text-medium">
                                            <span class="unit-detail2__table-value">{{order.wage_fixed_group5_value | currencyFloatFormat}}</span>
                                        </td>
                                        <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body unit-detail2__table-cell_text-medium">
                                            <span class="unit-detail2__table-value">{{order.wage_fixed_group6_value | currencyFloatFormat}}</span>
                                        </td>
                                        <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body unit-detail2__table-cell_text-medium">
                                            <span class="unit-detail2__table-value">{{order.wage_fixed_group7_value | currencyFloatFormat}}</span>
                                        </td>
                                        <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body unit-detail2__table-cell_text-medium">
                                            <span class="unit-detail2__table-value">{{order.wage_fixed_group8_value | currencyFloatFormat}}</span>
                                        </td>
                                    </template>
                                    <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body unit-detail2__table-cell_text-medium">
                                        <span class="unit-detail2__table-value">{{order.wage_change | currencyFloatFormat}}</span>
                                    </td>
                                    <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body unit-detail2__table-cell_text-medium">
                                        <span class="unit-detail2__table-value">{{order.wage_fixed | currencyFloatFormat}}</span>
                                    </td>
                                    <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body unit-detail2__table-cell_text-medium">
                                        <span class="unit-detail2__table-value">{{order.opex | currencyFloatFormat}}</span>
                                    </td>
                                    <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body unit-detail2__table-cell_text-medium">
                                        <span class="unit-detail2__table-value">{{order.result | currencyFloatFormat}}</span>
                                    </td>
                                    <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body unit-detail2__table-cell_text-medium">
                                        <span class="unit-detail2__table-value">{{order.is_profit}}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="unit-detail2__orders-loader" v-show="isOrdersPageLoading"></div>
                </div>
            </div>
            <div class="unit-detail2__compare-other-period" v-else-if="modeId === 'compareOtherPeriod'">
                <p class="unit-detail2__error" v-if="!compareUnit">Выберите период сравнения.</p>
                <template v-else>
                    <div class="unit-detail2__compare-tables"
                        v-if="(sourceId === 'distribution' && viewId === 'table') || sourceId === 'orders'"
                    >
                        <div class="unit-detail2__box box">
                            <table class="unit-detail2__table">
                                <caption class="unit-detail2__table-caption">Итого</caption>
                                <colgroup>
                                    <col class="unit-detail2__table-column unit-detail2__table-column_title">
                                </colgroup>
                                <colgroup>
                                    <col class="unit-detail2__table-column">
                                </colgroup>
                                <colgroup>
                                    <col class="unit-detail2__table-column">
                                </colgroup>
                                <colgroup>
                                    <col class="unit-detail2__table-column">
                                </colgroup>
                                <colgroup>
                                    <col class="unit-detail2__table-column">
                                    <col class="unit-detail2__table-column">
                                    <col class="unit-detail2__table-column">
                                </colgroup>
                                <colgroup>
                                    <col class="unit-detail2__table-column">
                                    <col class="unit-detail2__table-column">
                                    <col class="unit-detail2__table-column">
                                </colgroup>
                                <colgroup>
                                    <col class="unit-detail2__table-column">
                                </colgroup>
                                <colgroup>
                                    <col class="unit-detail2__table-column">
                                </colgroup>
                                <colgroup>
                                    <col class="unit-detail2__table-column">
                                </colgroup>
                                <thead>
                                    <tr class="unit-detail2__table-row unit-detail2__table-row_head">
                                        <th class="unit-detail2__table-cell unit-detail2__table-cell_vat unit-detail2__table-cell_text-small unit-detail2__table-cell_head"
                                            scope="col"
                                            rowspan="2"
                                        >
                                            <span class="unit-detail2__table-text">Источник</span>
                                        </th>
                                        <th class="unit-detail2__table-cell unit-detail2__table-cell_vat unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head"
                                            scope="col"
                                            rowspan="2"
                                        >
                                            <span class="unit-detail2__table-text">Заказы</span>
                                        </th>
                                        <th class="unit-detail2__table-cell unit-detail2__table-cell_vat unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head"
                                            scope="col"
                                            rowspan="2"
                                        >
                                            <span class="unit-detail2__table-text">Выручка</span>
                                        </th>
                                        <th class="unit-detail2__table-cell unit-detail2__table-cell_vat unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head"
                                            scope="col"
                                            rowspan="2"
                                        >
                                            <span class="unit-detail2__table-text">Ср. чек</span>
                                        </th>
                                        <th class="unit-detail2__table-cell unit-detail2__table-cell_text-small unit-detail2__table-cell_tac unit-detail2__table-cell_head"
                                            scope="col"
                                            colspan="3"
                                        >
                                            <span class="unit-detail2__table-text">Заказы в "+"</span>
                                        </th>
                                        <th class="unit-detail2__table-cell unit-detail2__table-cell_text-small unit-detail2__table-cell_tac unit-detail2__table-cell_head"
                                            scope="col"
                                            colspan="3"
                                        >
                                            <span class="unit-detail2__table-text">Заказы в "-"</span>
                                        </th>
                                        <th class="unit-detail2__table-cell  unit-detail2__table-cell_vat unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head"
                                            scope="col"
                                            rowspan="2"
                                        >
                                            <span class="unit-detail2__table-text">Дельта</span>
                                        </th>
                                        <th class="unit-detail2__table-cell  unit-detail2__table-cell_vat unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head"
                                            scope="col"
                                            rowspan="2"
                                        >
                                            <span class="unit-detail2__table-text">OPEX</span>
                                        </th>
                                        <th class="unit-detail2__table-cell  unit-detail2__table-cell_vat unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head"
                                            scope="col"
                                            rowspan="2"
                                        >
                                            <span class="unit-detail2__table-text">Ср.OPEX</span>
                                        </th>
                                    </tr>
                                    <tr class="unit-detail2__table-row unit-detail2__table-row_head">
                                        <th class="unit-detail2__table-cell unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head"
                                            scope="col"
                                        >
                                            <span class="unit-detail2__table-text">Количество</span>
                                        </th>
                                        <th class="unit-detail2__table-cell unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head"
                                            scope="col"
                                        >
                                            <span class="unit-detail2__table-text">Выручка</span>
                                        </th>
                                        <th class="unit-detail2__table-cell unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head"
                                            scope="col"
                                        >
                                            <span class="unit-detail2__table-text">Ср. чек</span>
                                        </th>
                                        <th class="unit-detail2__table-cell unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head"
                                            scope="col"
                                        >
                                            <span class="unit-detail2__table-text">Количество</span>
                                        </th>
                                        <th class="unit-detail2__table-cell unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head"
                                            scope="col"
                                        >
                                            <span class="unit-detail2__table-text">Выручка</span>
                                        </th>
                                        <th class="unit-detail2__table-cell unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head"
                                            scope="col"
                                        >
                                            <span class="unit-detail2__table-text">Ср. чек</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="unit-detail2__table-row unit-detail2__table-row_body">
                                        <th class="unit-detail2__table-cell unit-detail2__table-cell_text-small unit-detail2__table-cell_body"
                                            scope="row"
                                        >
                                            {{reportDaterange | daterangeText}}
                                        </th>
                                        <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                            <span class="unit-detail2__table-value">
                                                {{reportUnit.orders_count | intFormat}}
                                            </span>
                                            <difference class="unit-detail2__table-value"
                                                :primaryValue="reportUnit.orders_count"
                                                :compareValue="compareUnit.orders_count"
                                                mode="percentage"
                                                filter="percentFloatFormat"
                                            />
                                        </td>
                                        <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                            <span class="unit-detail2__table-value">
                                                {{reportUnit.value | currencyIntFormat}}
                                            </span>
                                            <difference class="unit-detail2__table-value"
                                                :primaryValue="reportUnit.value"
                                                :compareValue="compareUnit.value"
                                                mode="percentage"
                                                filter="percentFloatFormat"
                                            />
                                        </td>
                                        <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                            <span class="unit-detail2__table-value">
                                                {{reportUnit.average_bill | currencyIntFormat}}
                                            </span>
                                            <difference class="unit-detail2__table-value"
                                                :primaryValue="reportUnit.average_bill"
                                                :compareValue="compareUnit.average_bill"
                                                mode="subtraction"
                                                filter="currencyIntFormat"
                                            />
                                        </td>
                                        <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                            <span class="unit-detail2__table-value">
                                                {{reportUnit.orders_count_plus | intFormat}}
                                            </span>
                                            <difference class="unit-detail2__table-value"
                                                :primaryValue="reportUnit.orders_count_plus"
                                                :compareValue="compareUnit.orders_count_plus"
                                                mode="percentage"
                                                filter="percentFloatFormat"
                                            />
                                        </td>
                                        <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                            <span class="unit-detail2__table-value">
                                                {{reportUnit.value_plus | currencyIntFormat}}
                                            </span>
                                            <difference class="unit-detail2__table-value"
                                                :primaryValue="reportUnit.value_plus"
                                                :compareValue="compareUnit.value_plus"
                                                mode="percentage"
                                                filter="percentFloatFormat"
                                            />
                                        </td>
                                        <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                            <span class="unit-detail2__table-value">
                                                {{reportUnit.average_bill_plus | currencyIntFormat}}
                                            </span>
                                            <difference class="unit-detail2__table-value"
                                                :primaryValue="reportUnit.average_bill_plus"
                                                :compareValue="compareUnit.average_bill_plus"
                                                mode="subtraction"
                                                filter="currencyIntFormat"
                                            />
                                        </td>
                                        <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                            <span class="unit-detail2__table-value">
                                                {{reportUnit.orders_count_minus | intFormat}}
                                            </span>
                                            <difference class="unit-detail2__table-value"
                                                :primaryValue="reportUnit.orders_count_minus"
                                                :compareValue="compareUnit.orders_count_minus"
                                                mode="percentage"
                                                filter="percentFloatFormat"
                                            />
                                        </td>
                                        <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                            <span class="unit-detail2__table-value">
                                                {{reportUnit.value_minus | currencyIntFormat}}
                                            </span>
                                            <difference class="unit-detail2__table-value"
                                                :primaryValue="reportUnit.value_minus"
                                                :compareValue="compareUnit.value_minus"
                                                mode="percentage"
                                                filter="percentFloatFormat"
                                            />
                                        </td>
                                        <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                            <span class="unit-detail2__table-value">
                                                {{reportUnit.average_bill_minus | currencyIntFormat}}
                                            </span>
                                            <difference class="unit-detail2__table-value"
                                                :primaryValue="reportUnit.average_bill_minus"
                                                :compareValue="compareUnit.average_bill_minus"
                                                mode="subtraction"
                                                filter="currencyIntFormat"
                                            />
                                        </td>
                                        <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                            <span class="unit-detail2__table-value">
                                                {{reportUnit.average_bill_delta | currencyIntFormat}}
                                            </span>
                                            <difference class="unit-detail2__table-value"
                                                :primaryValue="reportUnit.average_bill_delta"
                                                :compareValue="compareUnit.average_bill_delta"
                                                mode="subtraction"
                                                filter="currencyIntFormat"
                                            />
                                        </td>
                                        <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                            <span class="unit-detail2__table-value">
                                                {{reportUnit.value_opex | currencyIntFormat}}
                                            </span>
                                            <difference class="unit-detail2__table-value"
                                                :primaryValue="reportUnit.value_opex"
                                                :compareValue="compareUnit.value_opex"
                                                mode="percentage"
                                                filter="percentFloatFormat"
                                            />
                                        </td>
                                        <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                            <span class="unit-detail2__table-value">
                                                {{reportUnit.average_opex | currencyIntFormat}}
                                            </span>
                                            <difference class="unit-detail2__table-value"
                                                :primaryValue="reportUnit.average_opex"
                                                :compareValue="compareUnit.average_opex"
                                                mode="subtraction"
                                                filter="currencyIntFormat"
                                            />
                                        </td>
                                    </tr>
                                    <tr class="unit-detail2__table-row unit-detail2__table-row_body">
                                        <th class="unit-detail2__table-cell unit-detail2__table-cell_text-small unit-detail2__table-cell_body"
                                            scope="row"
                                        >
                                            {{compareDaterange | daterangeText}}
                                        </th>
                                        <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                            {{compareUnit.orders_count | intFormat}}
                                        </td>
                                        <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                            {{compareUnit.value | currencyIntFormat}}
                                        </td>
                                        <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                            {{compareUnit.average_bill | currencyIntFormat}}
                                        </td>
                                        <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                            {{compareUnit.orders_count_plus | intFormat}}
                                        </td>
                                        <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                            {{compareUnit.value_plus | currencyIntFormat}}
                                        </td>
                                        <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                            {{compareUnit.average_bill_plus | currencyIntFormat}}
                                        </td>
                                        <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                            {{compareUnit.orders_count_minus | intFormat}}
                                        </td>
                                        <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                            {{compareUnit.value_minus | currencyIntFormat}}
                                        </td>
                                        <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                            {{compareUnit.average_bill_minus | currencyIntFormat}}
                                        </td>
                                        <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                            {{compareUnit.average_bill_delta | currencyIntFormat}}
                                        </td>
                                        <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                            {{compareUnit.value_opex | currencyIntFormat}}
                                        </td>
                                        <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                            {{compareUnit.average_opex | currencyIntFormat}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="unit-detail2__box box"
                            v-if="reportAverage"
                        >
                            <table class="unit-detail2__table">
                                <caption class="unit-detail2__table-caption">Среднее</caption>
                                <colgroup>
                                    <col class="unit-detail2__table-column unit-detail2__table-column_title">
                                </colgroup>
                                <colgroup>
                                    <col class="unit-detail2__table-column">
                                </colgroup>
                                <colgroup>
                                    <col class="unit-detail2__table-column">
                                </colgroup>
                                <colgroup>
                                    <col class="unit-detail2__table-column">
                                </colgroup>
                                <colgroup>
                                    <col class="unit-detail2__table-column">
                                    <col class="unit-detail2__table-column">
                                    <col class="unit-detail2__table-column">
                                </colgroup>
                                <colgroup>
                                    <col class="unit-detail2__table-column">
                                    <col class="unit-detail2__table-column">
                                    <col class="unit-detail2__table-column">
                                </colgroup>
                                <colgroup>
                                    <col class="unit-detail2__table-column">
                                </colgroup>
                                <colgroup>
                                    <col class="unit-detail2__table-column">
                                </colgroup>
                                <colgroup>
                                    <col class="unit-detail2__table-column">
                                </colgroup>
                                <thead>
                                    <tr class="unit-detail2__table-row unit-detail2__table-row_head">
                                        <th class="unit-detail2__table-cell unit-detail2__table-cell_vat unit-detail2__table-cell_text-small unit-detail2__table-cell_head"
                                            scope="col"
                                            rowspan="2"
                                        >
                                            <span class="unit-detail2__table-text">Источник</span>
                                        </th>
                                        <th class="unit-detail2__table-cell unit-detail2__table-cell_vat unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head"
                                            scope="col"
                                            rowspan="2"
                                        >
                                            <span class="unit-detail2__table-text">Заказы</span>
                                        </th>
                                        <th class="unit-detail2__table-cell unit-detail2__table-cell_vat unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head"
                                            scope="col"
                                            rowspan="2"
                                        >
                                            <span class="unit-detail2__table-text">Выручка</span>
                                        </th>
                                        <th class="unit-detail2__table-cell unit-detail2__table-cell_vat unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head"
                                            scope="col"
                                            rowspan="2"
                                        >
                                            <span class="unit-detail2__table-text">Ср. чек</span>
                                        </th>
                                        <th class="unit-detail2__table-cell unit-detail2__table-cell_text-small unit-detail2__table-cell_tac unit-detail2__table-cell_head"
                                            scope="col"
                                            colspan="3"
                                        >
                                            <span class="unit-detail2__table-text">Заказы в "+"</span>
                                        </th>
                                        <th class="unit-detail2__table-cell unit-detail2__table-cell_text-small unit-detail2__table-cell_tac unit-detail2__table-cell_head"
                                            scope="col"
                                            colspan="3"
                                        >
                                            <span class="unit-detail2__table-text">Заказы в "-"</span>
                                        </th>
                                        <th class="unit-detail2__table-cell  unit-detail2__table-cell_vat unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head"
                                            scope="col"
                                            rowspan="2"
                                        >
                                            <span class="unit-detail2__table-text">Дельта</span>
                                        </th>
                                        <th class="unit-detail2__table-cell  unit-detail2__table-cell_vat unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head"
                                            scope="col"
                                            rowspan="2"
                                        >
                                            <span class="unit-detail2__table-text">OPEX</span>
                                        </th>
                                        <th class="unit-detail2__table-cell  unit-detail2__table-cell_vat unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head"
                                            scope="col"
                                            rowspan="2"
                                        >
                                            <span class="unit-detail2__table-text">Ср.OPEX</span>
                                        </th>
                                    </tr>
                                    <tr class="unit-detail2__table-row unit-detail2__table-row_head">
                                        <th class="unit-detail2__table-cell unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head"
                                            scope="col"
                                        >
                                            <span class="unit-detail2__table-text">Количество</span>
                                        </th>
                                        <th class="unit-detail2__table-cell unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head"
                                            scope="col"
                                        >
                                            <span class="unit-detail2__table-text">Выручка</span>
                                        </th>
                                        <th class="unit-detail2__table-cell unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head"
                                            scope="col"
                                        >
                                            <span class="unit-detail2__table-text">Ср. чек</span>
                                        </th>
                                        <th class="unit-detail2__table-cell unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head"
                                            scope="col"
                                        >
                                            <span class="unit-detail2__table-text">Количество</span>
                                        </th>
                                        <th class="unit-detail2__table-cell unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head"
                                            scope="col"
                                        >
                                            <span class="unit-detail2__table-text">Выручка</span>
                                        </th>
                                        <th class="unit-detail2__table-cell unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head"
                                            scope="col"
                                        >
                                            <span class="unit-detail2__table-text">Ср. чек</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="unit-detail2__table-row unit-detail2__table-row_body">
                                        <th class="unit-detail2__table-cell unit-detail2__table-cell_text-small unit-detail2__table-cell_body"
                                            scope="row"
                                        >
                                            {{reportDaterange | daterangeText}}
                                        </th>
                                        <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                            <span class="unit-detail2__table-value">
                                                {{reportAverage.orders_count | intFormat}}
                                            </span>
                                            <difference class="unit-detail2__table-value"
                                                v-if="compareAverage"
                                                :primaryValue="reportAverage.orders_count"
                                                :compareValue="compareAverage.orders_count"
                                                mode="percentage"
                                                filter="percentFloatFormat"
                                            />
                                        </td>
                                        <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                            <span class="unit-detail2__table-value">
                                                {{reportAverage.value | currencyIntFormat}}
                                            </span>
                                            <difference class="unit-detail2__table-value"
                                                v-if="compareAverage"
                                                :primaryValue="reportAverage.value"
                                                :compareValue="compareAverage.value"
                                                mode="percentage"
                                                filter="percentFloatFormat"
                                            />
                                        </td>
                                        <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                            <span class="unit-detail2__table-value">
                                                {{reportUnit.average_bill | currencyIntFormat}}
                                            </span>
                                            <difference class="unit-detail2__table-value"
                                                :primaryValue="reportUnit.average_bill"
                                                :compareValue="compareUnit.average_bill"
                                                mode="subtraction"
                                                filter="currencyIntFormat"
                                            />
                                        </td>
                                        <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                            <span class="unit-detail2__table-value">
                                                {{reportAverage.orders_count_plus | intFormat}}
                                            </span>
                                            <difference class="unit-detail2__table-value"
                                                v-if="compareAverage"
                                                :primaryValue="reportAverage.orders_count_plus"
                                                :compareValue="compareAverage.orders_count_plus"
                                                mode="percentage"
                                                filter="percentFloatFormat"
                                            />
                                        </td>
                                        <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                            <span class="unit-detail2__table-value">
                                                {{reportAverage.value_plus | currencyIntFormat}}
                                            </span>
                                            <difference class="unit-detail2__table-value"
                                                v-if="compareAverage"
                                                :primaryValue="reportAverage.value_plus"
                                                :compareValue="compareAverage.value_plus"
                                                mode="percentage"
                                                filter="percentFloatFormat"
                                            />
                                        </td>
                                        <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                            <span class="unit-detail2__table-value">
                                                {{reportUnit.average_bill_plus | currencyIntFormat}}
                                            </span>
                                            <difference class="unit-detail2__table-value"
                                                :primaryValue="reportUnit.average_bill_plus"
                                                :compareValue="compareUnit.average_bill_plus"
                                                mode="subtraction"
                                                filter="currencyIntFormat"
                                            />
                                        </td>
                                        <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                            <span class="unit-detail2__table-value">
                                                {{reportAverage.orders_count_minus | intFormat}}
                                            </span>
                                            <difference class="unit-detail2__table-value"
                                                v-if="compareAverage"
                                                :primaryValue="reportAverage.orders_count_minus"
                                                :compareValue="compareAverage.orders_count_minus"
                                                mode="percentage"
                                                filter="percentFloatFormat"
                                            />
                                        </td>
                                        <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                            <span class="unit-detail2__table-value">
                                                {{reportAverage.value_minus | currencyIntFormat}}
                                            </span>
                                            <difference class="unit-detail2__table-value"
                                                v-if="compareAverage"
                                                :primaryValue="reportAverage.value_minus"
                                                :compareValue="compareAverage.value_minus"
                                                mode="percentage"
                                                filter="percentFloatFormat"
                                            />
                                        </td>
                                        <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                            <span class="unit-detail2__table-value">
                                                {{reportUnit.average_bill_minus | currencyIntFormat}}
                                            </span>
                                            <difference class="unit-detail2__table-value"
                                                :primaryValue="reportUnit.average_bill_minus"
                                                :compareValue="compareUnit.average_bill_minus"
                                                mode="subtraction"
                                                filter="currencyIntFormat"
                                            />
                                        </td>
                                        <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                            <span class="unit-detail2__table-value">
                                                {{reportUnit.average_bill_delta | currencyIntFormat}}
                                            </span>
                                            <difference class="unit-detail2__table-value"
                                                :primaryValue="reportUnit.average_bill_delta"
                                                :compareValue="compareUnit.average_bill_delta"
                                                mode="subtraction"
                                                filter="currencyIntFormat"
                                            />
                                        </td>
                                        <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                            <span class="unit-detail2__table-value">
                                                {{reportAverage.value_opex | currencyIntFormat}}
                                            </span>
                                            <difference class="unit-detail2__table-value"
                                                v-if="compareAverage"
                                                :primaryValue="reportAverage.value_opex"
                                                :compareValue="compareAverage.value_opex"
                                                mode="percentage"
                                                filter="percentFloatFormat"
                                            />
                                        </td>
                                        <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                            <span class="unit-detail2__table-value">
                                                {{reportUnit.average_opex | currencyIntFormat}}
                                            </span>
                                            <difference class="unit-detail2__table-value"
                                                :primaryValue="reportUnit.average_opex"
                                                :compareValue="compareUnit.average_opex"
                                                mode="subtraction"
                                                filter="currencyIntFormat"
                                            />
                                        </td>
                                    </tr>
                                    <tr class="unit-detail2__table-row unit-detail2__table-row_body"
                                        v-if="compareAverage"
                                    >
                                        <th class="unit-detail2__table-cell unit-detail2__table-cell_text-small unit-detail2__table-cell_body" scope="row">
                                            {{compareDaterange | daterangeText}}
                                        </th>
                                        <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                            {{compareAverage.orders_count | intFormat}}
                                        </td>
                                        <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                            {{compareAverage.value | currencyIntFormat}}
                                        </td>
                                        <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                            {{compareUnit.average_bill | currencyIntFormat}}
                                        </td>
                                        <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                            {{compareAverage.orders_count_plus | intFormat}}
                                        </td>
                                        <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                            {{compareAverage.value_plus | currencyIntFormat}}
                                        </td>
                                        <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                            {{compareUnit.average_bill_plus | currencyIntFormat}}
                                        </td>
                                        <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                            {{compareAverage.orders_count_minus | intFormat}}
                                        </td>
                                        <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                            {{compareAverage.value_minus | currencyIntFormat}}
                                        </td>
                                        <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                            {{compareUnit.average_bill_minus | currencyIntFormat}}
                                        </td>
                                        <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                            {{compareUnit.average_bill_delta | currencyIntFormat}}
                                        </td>
                                        <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                            {{compareAverage.value_opex | currencyIntFormat}}
                                        </td>
                                        <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                            {{compareUnit.average_opex | currencyIntFormat}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <template v-if="sourceId === 'distribution' && viewId === 'table'">
                            <div class="unit-detail2__box box"
                                v-for="item in reportItems" :key="item.id"
                            >
                                <table class="unit-detail2__table">
                                    <caption class="unit-detail2__table-caption">
                                        <router-link class="link link_red"
                                            v-if="!isUserWithOneRestaurant"
                                            :to="{ name: 'UnitDetail', params: { id: item.id } }"
                                            :title='`Смотреть Unit-экономику ${sourceId === "restaurants" ? "ресторана" : "источника"} "${item.title}"`'>
                                            {{item.title}}
                                        </router-link>
                                        <template v-else>{{item.title}}</template>
                                    </caption>
                                    <colgroup>
                                        <col class="unit-detail2__table-column unit-detail2__table-column_title">
                                    </colgroup>
                                    <colgroup>
                                        <col class="unit-detail2__table-column">
                                    </colgroup>
                                    <colgroup>
                                        <col class="unit-detail2__table-column">
                                    </colgroup>
                                    <colgroup>
                                        <col class="unit-detail2__table-column">
                                    </colgroup>
                                    <colgroup>
                                        <col class="unit-detail2__table-column">
                                        <col class="unit-detail2__table-column">
                                        <col class="unit-detail2__table-column">
                                    </colgroup>
                                    <colgroup>
                                        <col class="unit-detail2__table-column">
                                        <col class="unit-detail2__table-column">
                                        <col class="unit-detail2__table-column">
                                    </colgroup>
                                    <colgroup>
                                        <col class="unit-detail2__table-column">
                                    </colgroup>
                                    <colgroup>
                                        <col class="unit-detail2__table-column">
                                    </colgroup>
                                    <colgroup>
                                        <col class="unit-detail2__table-column">
                                    </colgroup>
                                    <thead>
                                        <tr class="unit-detail2__table-row unit-detail2__table-row_head">
                                            <th class="unit-detail2__table-cell unit-detail2__table-cell_vat unit-detail2__table-cell_text-small unit-detail2__table-cell_head"
                                                scope="col"
                                                rowspan="2"
                                            >
                                                <span class="unit-detail2__table-text">Источник</span>
                                            </th>
                                            <th class="unit-detail2__table-cell unit-detail2__table-cell_vat unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head"
                                                scope="col"
                                                rowspan="2"
                                            >
                                                <span class="unit-detail2__table-text">Заказы</span>
                                            </th>
                                            <th class="unit-detail2__table-cell unit-detail2__table-cell_vat unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head"
                                                scope="col"
                                                rowspan="2"
                                            >
                                                <span class="unit-detail2__table-text">Выручка</span>
                                            </th>
                                            <th class="unit-detail2__table-cell unit-detail2__table-cell_vat unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head"
                                                scope="col"
                                                rowspan="2"
                                            >
                                                <span class="unit-detail2__table-text">Ср. чек</span>
                                            </th>
                                            <th class="unit-detail2__table-cell unit-detail2__table-cell_text-small unit-detail2__table-cell_tac unit-detail2__table-cell_head"
                                                scope="col"
                                                colspan="3"
                                            >
                                                <span class="unit-detail2__table-text">Заказы в "+"</span>
                                            </th>
                                            <th class="unit-detail2__table-cell unit-detail2__table-cell_text-small unit-detail2__table-cell_tac unit-detail2__table-cell_head"
                                                scope="col"
                                                colspan="3"
                                            >
                                                <span class="unit-detail2__table-text">Заказы в "-"</span>
                                            </th>
                                            <th class="unit-detail2__table-cell  unit-detail2__table-cell_vat unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head"
                                                scope="col"
                                                rowspan="2"
                                            >
                                                <span class="unit-detail2__table-text">Дельта</span>
                                            </th>
                                            <th class="unit-detail2__table-cell  unit-detail2__table-cell_vat unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head"
                                                scope="col"
                                                rowspan="2"
                                            >
                                                <span class="unit-detail2__table-text">OPEX</span>
                                            </th>
                                            <th class="unit-detail2__table-cell  unit-detail2__table-cell_vat unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head"
                                                scope="col"
                                                rowspan="2"
                                            >
                                                <span class="unit-detail2__table-text">Ср. OPEX</span>
                                            </th>
                                        </tr>
                                        <tr class="unit-detail2__table-row unit-detail2__table-row_head">
                                            <th class="unit-detail2__table-cell unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head"
                                                scope="col"
                                            >
                                                <span class="unit-detail2__table-text">Количество</span>
                                            </th>
                                            <th class="unit-detail2__table-cell unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head"
                                                scope="col"
                                            >
                                                <span class="unit-detail2__table-text">Выручка</span>
                                            </th>
                                            <th class="unit-detail2__table-cell unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head"
                                                scope="col"
                                            >
                                                <span class="unit-detail2__table-text">Ср. чек</span>
                                            </th>
                                            <th class="unit-detail2__table-cell unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head"
                                                scope="col"
                                            >
                                                <span class="unit-detail2__table-text">Количество</span>
                                            </th>
                                            <th class="unit-detail2__table-cell unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head"
                                                scope="col"
                                            >
                                                <span class="unit-detail2__table-text">Выручка</span>
                                            </th>
                                            <th class="unit-detail2__table-cell unit-detail2__table-cell_text-small unit-detail2__table-cell_tar unit-detail2__table-cell_head"
                                                scope="col"
                                            >
                                                <span class="unit-detail2__table-text">Ср. чек</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="unit-detail2__table-row unit-detail2__table-row_body">
                                            <th class="unit-detail2__table-cell unit-detail2__table-cell_text-small unit-detail2__table-cell_body unit-detail2__table-cell_title"
                                                scope="row"
                                            >
                                                {{reportDaterange | daterangeText}}
                                            </th>
                                            <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                                <span class="unit-detail2__table-value">{{item.orders_count | intFormat}}</span>
                                                <difference class="unit-detail2__table-value"
                                                    v-if="findItem(compareItems, item.id)"
                                                    :primaryValue="item.orders_count"
                                                    :compareValue="findItem(compareItems, item.id).orders_count"
                                                    mode="percentage"
                                                    filter="percentFloatFormat"
                                                />
                                            </td>
                                            <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                                <span class="unit-detail2__table-value">{{item.value | currencyIntFormat}}</span>
                                                <difference class="unit-detail2__table-value"
                                                    v-if="findItem(compareItems, item.id)"
                                                    :primaryValue="item.value"
                                                    :compareValue="findItem(compareItems, item.id).value"
                                                    mode="percentage"
                                                    filter="percentFloatFormat"
                                                />
                                            </td>
                                            <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                                <span class="unit-detail2__table-value">{{item.average_bill | currencyIntFormat}}</span>
                                                <difference class="unit-detail2__table-value"
                                                    v-if="findItem(compareItems, item.id)"
                                                    :primaryValue="item.average_bill"
                                                    :compareValue="findItem(compareItems, item.id).average_bill"
                                                    mode="subtraction"
                                                    filter="currencyIntFormat"
                                                />
                                            </td>
                                            <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                                <span class="unit-detail2__table-value">{{item.orders_count_plus | intFormat}}</span>
                                                <difference class="unit-detail2__table-value"
                                                    v-if="findItem(compareItems, item.id)"
                                                    :primaryValue="item.orders_count_plus"
                                                    :compareValue="findItem(compareItems, item.id).orders_count_plus"
                                                    mode="percentage"
                                                    filter="percentFloatFormat"
                                                />
                                            </td>
                                            <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                                <span class="unit-detail2__table-value">{{item.value_plus | currencyIntFormat}}</span>
                                                <difference class="unit-detail2__table-value"
                                                    v-if="findItem(compareItems, item.id)"
                                                    :primaryValue="item.value_plus"
                                                    :compareValue="findItem(compareItems, item.id).value_plus"
                                                    mode="percentage"
                                                    filter="percentFloatFormat"
                                                />
                                            </td>
                                            <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                                <span class="unit-detail2__table-value">{{item.average_bill_plus | currencyIntFormat}}</span>
                                                <difference class="unit-detail2__table-value"
                                                    v-if="findItem(compareItems, item.id)"
                                                    :primaryValue="item.average_bill_plus"
                                                    :compareValue="findItem(compareItems, item.id).average_bill_plus"
                                                    mode="subtraction"
                                                    filter="currencyIntFormat"
                                                />
                                            </td>
                                            <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                                <span class="unit-detail2__table-value">{{item.orders_count_minus | intFormat}}</span>
                                                <difference class="unit-detail2__table-value"
                                                    v-if="findItem(compareItems, item.id)"
                                                    :primaryValue="item.orders_count_minus"
                                                    :compareValue="findItem(compareItems, item.id).orders_count_minus"
                                                    mode="percentage"
                                                    filter="percentFloatFormat"
                                                />
                                            </td>
                                            <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                                <span class="unit-detail2__table-value">{{item.value_minus | currencyIntFormat}}</span>
                                                <difference class="unit-detail2__table-value"
                                                    v-if="findItem(compareItems, item.id)"
                                                    :primaryValue="item.value_minus"
                                                    :compareValue="findItem(compareItems, item.id).value_minus"
                                                    mode="percentage"
                                                    filter="percentFloatFormat"
                                                />
                                            </td>
                                            <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                                <span class="unit-detail2__table-value">{{item.average_bill_minus | currencyIntFormat}}</span>
                                                <difference class="unit-detail2__table-value"
                                                    v-if="findItem(compareItems, item.id)"
                                                    :primaryValue="item.average_bill_minus"
                                                    :compareValue="findItem(compareItems, item.id).average_bill_minus"
                                                    mode="subtraction"
                                                    filter="currencyIntFormat"
                                                />
                                            </td>
                                            <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                                <span class="unit-detail2__table-value">{{item.average_bill_delta | currencyIntFormat}}</span>
                                                <difference class="unit-detail2__table-value"
                                                    v-if="findItem(compareItems, item.id)"
                                                    :primaryValue="item.average_bill_delta"
                                                    :compareValue="findItem(compareItems, item.id).average_bill_delta"
                                                    mode="subtraction"
                                                    filter="currencyIntFormat"
                                                />
                                            </td>
                                            <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                                <span class="unit-detail2__table-value">{{item.value_opex | currencyIntFormat}}</span>
                                                <difference class="unit-detail2__table-value"
                                                    v-if="findItem(compareItems, item.id)"
                                                    :primaryValue="item.value_opex"
                                                    :compareValue="findItem(compareItems, item.id).value_opex"
                                                    mode="percentage"
                                                    filter="percentFloatFormat"
                                                />
                                            </td>
                                            <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                                <span class="unit-detail2__table-value">{{item.average_opex | currencyIntFormat}}</span>
                                                <difference class="unit-detail2__table-value"
                                                    v-if="findItem(compareItems, item.id)"
                                                    :primaryValue="item.average_opex"
                                                    :compareValue="findItem(compareItems, item.id).average_opex"
                                                    mode="subtraction"
                                                    filter="currencyIntFormat"
                                                />
                                            </td>
                                        </tr>
                                        <tr class="unit-detail2__table-row unit-detail2__table-row_body"
                                            v-if="findItem(compareItems, item.id)"
                                        >
                                            <th class="unit-detail2__table-cell unit-detail2__table-cell_text-small unit-detail2__table-cell_body" scope="row">
                                                {{compareDaterange | daterangeText}}
                                            </th>
                                            <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                                <span class="unit-detail2__table-value">
                                                    {{findItem(compareItems, item.id).orders_count | intFormat}}
                                                </span>
                                            </td>
                                            <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                                <span class="unit-detail2__table-value">
                                                    {{findItem(compareItems, item.id).value | currencyIntFormat}}
                                                </span>
                                            </td>
                                            <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                                <span class="unit-detail2__table-value">
                                                    {{findItem(compareItems, item.id).average_bill | currencyIntFormat}}
                                                </span>
                                            </td>
                                            <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                                <span class="unit-detail2__table-value">
                                                    {{findItem(compareItems, item.id).orders_count_plus | intFormat}}
                                                </span>
                                            </td>
                                            <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                                <span class="unit-detail2__table-value">
                                                    {{findItem(compareItems, item.id).value_plus | currencyIntFormat}}
                                                </span>
                                            </td>
                                            <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                                <span class="unit-detail2__table-value">
                                                    {{findItem(compareItems, item.id).average_bill_plus | currencyIntFormat}}
                                                </span>
                                            </td>
                                            <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                                <span class="unit-detail2__table-value">
                                                    {{findItem(compareItems, item.id).orders_count_minus | intFormat}}
                                                </span>
                                            </td>
                                            <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                                <span class="unit-detail2__table-value">
                                                    {{findItem(compareItems, item.id).value_minus | currencyIntFormat}}
                                                </span>
                                            </td>
                                            <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                                <span class="unit-detail2__table-value">
                                                    {{findItem(compareItems, item.id).average_bill_minus | currencyIntFormat}}
                                                </span>
                                            </td>
                                            <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                                <span class="unit-detail2__table-value">
                                                    {{findItem(compareItems, item.id).average_bill_delta | currencyIntFormat}}
                                                </span>
                                            </td>
                                            <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                                <span class="unit-detail2__table-value">
                                                    {{findItem(compareItems, item.id).value_opex | currencyIntFormat}}
                                                </span>
                                            </td>
                                            <td class="unit-detail2__table-cell unit-detail2__table-cell_tar unit-detail2__table-cell_body">
                                                <span class="unit-detail2__table-value">
                                                    {{findItem(compareItems, item.id).average_opex | currencyIntFormat}}
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </template>
                    </div>
                    <div class="unit-detail2__compare-distribution-chart"
                        v-else-if="sourceId === 'distribution' && viewId === 'chart'"
                    >
                        <div class="unit-detail2__box unit-detail2__box_chart box">
                            <highcharts class="unit-detail2__chart" :options="unitItemsColumnChartOptions" :key="'unitItemsCompareColumnChart'" />
                        </div>
                        <div class="unit-detail2__box unit-detail2__box_charts box"
                            v-if="!chartFieldId.startsWith('average_bill')"
                        >
                            <highcharts class="unit-detail2__chart" :options="unitItemsPieChartOptions(reportItems, 'Период отчета')" :key="'unitItemsCompareReportPieChart'" />
                            <highcharts class="unit-detail2__chart" :options="unitItemsPieChartOptions(compareItems, 'Период сравнения')" :key="'unitItemsComparePieChart'" />
                        </div>
                    </div>
                </template>
            </div>
        </section>
    </div>
</template>

<script>
    import { mapState, mapMutations, mapActions } from "vuex";
    import { daterangeKey, isDaterangeSet, daterangeText, dateText } from "@/helpers/daterange";
    import { compareStrings, compareNumbers } from "@/helpers/compare";
    import { intFormat, currencyIntFormat } from "@/helpers/filters";
    import parseISO from "date-fns/parseISO";
    import DaterangePicker from "@/components/DaterangePicker";
    import Difference from "@/components/Difference";
    import Pagination from "@/components/Pagination";
    import Checkbox from "@/components/Checkbox";

    export default {
        name: "UnitDetail2",
        props: {
            id: {},
            reportDaterange: {
                type: Object,
                required: true
            },
            compareDaterange: {
                required: true
            },
            userRestaurants: {
                required: true
            },
            userUnitOrigins: {
                required: true
            }
        },
        components: {
            DaterangePicker,
            Difference,
            Pagination,
            Checkbox
        },
        data() {
            return {
                status: "loading",
                isOrdersPageLoading: false,
                ordersBoxMaxHeight: null
            };
        },
        computed: {
            ...mapState({
                title: state => state.page.title,
                modeId: state => state.unit2.modeId,
                sorting: state => state.unit2.sorting,
                type: state => state.unit2.type,
                sourceId: state => state.unit2.sourceId,
                distributionId: state => state.unit2.distributionId,
                viewId: state => state.unit2.viewId,
                showWageGroups: state => state.unit2.showWageGroups,
                chartFieldId: state => state.unit2.chartFieldId,
                ordersTotalCount: state => state.unit2.ordersTotalCount,
                ordersCurrentPage: state => state.unit2.ordersCurrentPage,
                pagesTotalCount: state => state.unit2.pagesTotalCount,
                totalData: state => state.unit2.data.total,
                ordersData: state => state.unit2.data.orders,
            }),
            reportDaterangeModel: {
                get() {
                    return this.reportDaterange;
                },
                set(daterange) {
                    this.setReportDaterange(daterange);
                }
            },
            compareDaterangeModel: {
                get() {
                    return this.compareDaterange;
                },
                set(daterange) {
                    this.setUnitCompareDaterange(daterange);
                }
            },
            modeModel: {
                get() {
                    return this.modeId === "compareOtherPeriod";
                },
                set(isCompareOtherPeriod) {
                    this.setUnitParameter({
                        parameter: "modeId",
                        value: isCompareOtherPeriod ? "compareOtherPeriod" : "base"
                    });
                }
            },
            showWageGroupsModel: {
                get() {
                    return this.showWageGroups;
                },
                set(value) {
                    this.setUnitParameter({ parameter: "showWageGroups", value });
                }
            },
            isUserWithOneRestaurant() {
                return this.userRestaurants?.ids?.length === 1;
            },
            modeToggleLabels() {
                return {
                    unchecked: "Данные периода",
                    checked: "Сравнение периодов"
                };
            },
            toggleColors() {
                return {
                    unchecked: "#e64d53",
                    checked: "#e0121a"
                };
            },
            sourceToggleLabels() {
                return {
                    unchecked: "Распределение",
                    checked: "Заказы"
                };
            },
            sourceModel: {
                get() {
                    return this.sourceId === "orders";
                },
                set(isOrders) {
                    this.setUnitParameter({
                        parameter: "sourceId",
                        value: isOrders ? "orders" : "distribution"
                    });
                }
            },
            distributionToggleLabels() {
                return {
                    unchecked: "Рестораны",
                    checked: "Источники"
                };
            },
            distributionModel: {
                get() {
                    return this.distributionId === "origins";
                },
                set(isOrigins) {
                    this.setUnitParameter({
                        parameter: "distributionId",
                        value: isOrigins ? "origins" : "restaurants"
                    });
                }
            },
            viewToggleLabels() {
                return {
                    unchecked: "Таблица",
                    checked: "График"
                };
            },
            viewModel: {
                get() {
                    return this.viewId === "chart";
                },
                set(isChart) {
                    this.setUnitParameter({
                        parameter: "viewId",
                        value: isChart ? "chart" : "table"
                    });
                }
            },
            unitFields() {
                return [
                    { id: "orders_count", title: "Заказы", formatter: intFormat },
                    { id: "value", title: "Выручка", formatter: currencyIntFormat },
                    { id: "average_bill", title: "Ср. чек", formatter: currencyIntFormat },
                    { id: "less_than_800", title: "Меньше 800", formatter: intFormat },
                    { id: "orders_count_plus", title: `Заказы в "+"`, formatter: intFormat },
                    { id: "value_plus", title: `Выручка заказов в "+"`, formatter: currencyIntFormat },
                    { id: "average_bill_plus", title: `Ср. чек заказов в "+"`, formatter: currencyIntFormat },
                    { id: "orders_count_minus", title: `Заказы в "-"`, formatter: intFormat },
                    { id: "value_minus", title: `Выручка заказов в "-"`, formatter: currencyIntFormat },
                    { id: "average_bill_minus", title: `Ср. чек заказов в "-"`, formatter: currencyIntFormat },
                    { id: "average_bill_delta", title: "Дельта", formatter: currencyIntFormat },
                    { id: "value_opex", title: "OPEX", formatter: currencyIntFormat },
                    { id: "average_opex", title: "Ср. OPEX", formatter: currencyIntFormat },
                ];
            },
            chartFieldModel: {
                get() {
                    return this.chartFieldId;
                },
                set(chartFieldId) {
                    this.setUnitParameter({
                        parameter: "chartFieldId",
                        value: chartFieldId
                    });
                }
            },
            requiredDateranges() {
                let dateranges = [this.reportDaterange];

                if (this.modeId === "compareOtherPeriod" && isDaterangeSet(this.compareDaterange)) {
                    dateranges.push(this.compareDaterange);
                }

                return dateranges;
            },
            requiredUnits() {
                return this.requiredDateranges.map(daterange => this.totalData[daterangeKey(daterange)]);
            },
            reportUnit() {
                if (this.type === "total") {
                    return this.totalData[daterangeKey(this.reportDaterange)];
                } else {
                    return this.totalData[daterangeKey(this.reportDaterange)]?.[this.type]?.find(({ id }) => id === this.id);
                }
            },
            reportItems() {
                return this.reportUnit?.[this.distributionId]?.sort((item1, item2) => {
                    if (this.sorting.distribution.field === "title") {
                        return compareStrings(
                            item1.title,
                            item2.title,
                            this.sorting.distribution.direction
                        );
                    }

                    return compareNumbers(
                        item1[this.sorting.distribution.field],
                        item2[this.sorting.distribution.field],
                        this.sorting.distribution.direction
                    );
                });
            },
            reportAverage() {
                if (this.reportItems?.length > 1) {
                    return {
                        orders_count: this.reportUnit.orders_count / this.reportItems.length,
                        value: this.reportUnit.value / this.reportItems.length,
                        less_than_800: this.reportUnit.less_than_800 / this.reportItems.length,
                        orders_count_plus: this.reportUnit.orders_count_plus / this.reportItems.length,
                        orders_count_minus: this.reportUnit.orders_count_minus / this.reportItems.length,
                        value_plus: this.reportUnit.value_plus / this.reportItems.length,
                        value_minus: this.reportUnit.value_minus / this.reportItems.length,
                        value_opex: this.reportUnit.value_opex / this.reportItems.length,
                    };
                }
            },
            compareUnit() {
                if (isDaterangeSet(this.compareDaterange)) {
                    if (this.type === "total") {
                        return this.totalData[daterangeKey(this.compareDaterange)];
                    } else {
                        return this.totalData[daterangeKey(this.compareDaterange)]?.[this.type]?.find(({ id }) => id === this.id);
                    }
                }
            },
            compareItems() {
                return this.compareUnit?.[this.distributionId]?.sort((item1, item2) => {
                    if (this.sorting.distribution.field === "title") {
                        return compareStrings(
                            item1.title,
                            item2.title,
                            this.sorting.distribution.direction
                        );
                    }

                    return compareNumbers(
                        item1[this.sorting.distribution.field],
                        item2[this.sorting.distribution.field],
                        this.sorting.distribution.direction
                    );
                });
            },
            compareAverage() {
                if (this.compareItems?.length > 1) {
                    return {
                        orders_count: this.compareUnit.orders_count / this.compareItems.length,
                        value: this.compareUnit.value / this.compareItems.length,
                        less_than_800: this.compareUnit.less_than_800 / this.compareItems.length,
                        orders_count_plus: this.compareUnit.orders_count_plus / this.compareItems.length,
                        orders_count_minus: this.compareUnit.orders_count_minus / this.compareItems.length,
                        value_plus: this.compareUnit.value_plus / this.compareItems.length,
                        value_minus: this.compareUnit.value_minus / this.compareItems.length,
                        value_opex: this.compareUnit.value_opex / this.compareItems.length,
                    };
                }
            },
            unitItemsColumnChartOptions() {
                let field = this.unitFields.find(({id}) => id === this.chartFieldId);

                let options = {
                    chart: {
                        type: "column"
                    },
                    colors: ["#e0121a", "#ff5261"],
                    title: {
                        text: null
                    },
                    xAxis: {
                        categories: []
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: field?.title
                        },
                        stackLabels: {
                            enabled: true,
                            style: {
                                fontWeight: "bold",
                                color: "gray"
                            }
                        },
                        plotLines: []
                    },
                    legend: {
                        align: "center",
                        verticalAlign: "bottom",
                    },
                    tooltip: {
                        headerFormat: "<b>{point.x}</b><br/>",
                        pointFormatter() {
                            return `${this.series.name}: ${field.formatter(this.y)}`;
                        }
                    },
                    plotOptions: {
                        column: {
                            dataLabels: {
                                enabled: true,
                                formatter() {
                                    return field.formatter(this.y);
                                }
                            }
                        }
                    },
                    series: [{
                        name: "Период отчёта",
                        data: []
                    }]
                };

                this.reportItems?.forEach(reportItem => {
                    options.xAxis.categories.push(reportItem.title);
                    options.series[0].data.push(reportItem[this.chartFieldId]);
                });

                options.yAxis.plotLines.push({
                    width: 1,
                    color: "#000",
                    dashStyle: "LongDashDot",
                    value: this.chartFieldId.startsWith("average_bill") ?
                        this.reportUnit[this.chartFieldId] :
                        this.reportAverage[this.chartFieldId]
                });

                if (this.modeId === "compareOtherPeriod" && this.compareItems) {
                    options.series.push({
                        name: "Период сравнения",
                        data: []
                    });

                    this.reportItems?.forEach(reportItem => {
                        let compareItem = this.findItem(this.compareItems, reportItem.id);
                        options.series[1].data.push(compareItem ? compareItem[this.chartFieldId] : null);
                    });

                    options.yAxis.plotLines.push({
                        width: 1,
                        color: "#000",
                        dashStyle: "LongDashDotDot",
                        value: this.chartFieldId.startsWith("average_bill") ?
                            this.compareUnit[this.chartFieldId] :
                            this.compareAverage[this.chartFieldId]
                    });
                }

                return options;
            },
            orders() {
                return this.ordersData?.map(order => {
                    if (order.date && !(order.date instanceof Date)) {
                        order.date = parseISO(order.date);
                    }

                    return order;
                });
            },
            ordersPageModel: {
                get() {
                    return this.ordersCurrentPage;
                },
                set(ordersCurrentPage) {
                    this.setUnitParameter({
                        parameter: "ordersCurrentPage",
                        value: ordersCurrentPage
                    });
                    this.loadOrdersPage();
                }
            },
        },
        methods: {
            ...mapMutations([
                "setTitle",
                "setReportDaterange",
            ]),
            ...mapMutations({
                setUnitCompareDaterange: "setUnitCompareDaterange2",
                setUnitParameter: "setUnitParameter2",
                setUnitSorting: "setUnitSorting2",
                resetDaterangeUnitOrders: "resetDaterangeUnitOrders2",
            }),
            ...mapActions({
                requestTotalUnits: "requestTotalUnits2",
                requestUnitOrders: "requestUnitOrders2",
            }),
            setSorting(table, field) {
                this.setUnitSorting({ table, field });

                if (table === "orders") {
                    this.loadOrders();
                }
            },
            showReport() {
                this.status = "success";
            },
            loadUnits() {
                this.status = "loading";

                let daterangesWithoutData = this.requiredDateranges.filter(
                    daterange => !Boolean(this.totalData[daterangeKey(daterange)])
                );

                this.requestTotalUnits(daterangesWithoutData).then(() => {
                    this.showReport();
                }).catch(() => {
                    this.status = "error";
                });
            },
            loadOrders() {
                this.status = "loading";

                this.resetDaterangeUnitOrders();

                this.requestUnitOrders({
                    daterange: this.reportDaterange,
                    id: this.id ?? "total"
                }).then(() => {
                    this.showReport();
                }).catch(() => {
                    this.status = "error";
                });
            },
            loadOrdersPage() {
                this.$refs.ordersBox.scrollLeft = 0;
                this.$refs.ordersBox.scrollTop = 0;
                this.isOrdersPageLoading = true;

                this.requestUnitOrders({
                    daterange: this.reportDaterange,
                    id: this.id ?? "total"
                }).then(() => {
                    this.isOrdersPageLoading = false;
                }).catch(() => {
                    this.status = "error";
                });
            },
            findItem(items, itemId) {
                return items?.find(({ id }) => id === itemId);
            },
            unitItemsPieChartOptions(items, title = null) {
                let field = this.unitFields.find(({id}) => id === this.chartFieldId);

                let options = {
                    chart: {
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        type: "pie"
                    },
                    title: {
                        text: title
                    },
                    tooltip: {
                        pointFormatter() {
                            return `${field.formatter(this.y)} из ${field.formatter(this.total)}`;
                        }
                    },
                    plotOptions: {
                        pie: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: true,
                                format: '{point.name}: {point.percentage:.1f} %'
                            }
                        }
                    },
                    series: [{
                        data: []
                    }]
                };

                items?.forEach(item => {
                    options.series[0].data.push({
                        name: item.title,
                        y: item[this.chartFieldId]
                    });
                });

                return options;
            },
            initUnitDetail() {
                if (
                    typeof this.id !== "undefined" &&
                    !this.userUnitOrigins?.ids?.includes(this.id) &&
                    !this.userRestaurants?.ids?.includes(this.id)
                ) {
                    this.$router.push({ name: "Error401" });
                } else {
                    if (typeof this.id === "undefined") {
                        if (this.userRestaurants?.ids?.length === 1) {
                            this.$router.push({
                                name: "UnitDetail",
                                params:{
                                    id: this.userRestaurants?.ids[0]
                                }
                            });
                        } else {
                            this.setUnitParameter({
                                parameter: "type",
                                value: "total"
                            });
                            this.setUnitParameter({
                                parameter: "distributionId",
                                value: "restaurants"
                            });
                        }
                    } else if (this.userUnitOrigins?.ids?.includes(this.id)) {
                        if (this.userRestaurants?.ids?.length === 1) {
                            this.$router.push({
                                name: "UnitDetail",
                                params:{
                                    id: this.userRestaurants?.ids[0]
                                }
                            });
                        } else {
                            this.setUnitParameter({
                                parameter: "type",
                                value: "origins"
                            });
                            this.setUnitParameter({
                                parameter: "distributionId",
                                value: "restaurants"
                            });

                            if (this.sorting.orders.field === "origin") {
                                this.setSorting("orders", "department");
                            }

                            let originTitle = this.userUnitOrigins?.byId?.[this.id]?.title;

                            if (originTitle && !this.title.includes(originTitle)) {
                                this.setTitle(`${this.title} источника "${originTitle}"`);
                            }
                        }
                    } else if (this.userRestaurants?.ids?.includes(this.id)) {
                        this.setUnitParameter({
                            parameter: "type",
                            value: "restaurants"
                        });
                        this.setUnitParameter({
                            parameter: "distributionId",
                            value: "origins"
                        });

                        if (this.sorting.orders.field === "department") {
                            this.setSorting("orders", "origin");
                        }

                        let restaurantTitle = this.userRestaurants?.byId?.[this.id]?.title;

                        if (restaurantTitle && !this.title.includes(restaurantTitle)) {
                            this.setTitle(`${this.title} ресторана "${restaurantTitle}"`);
                        }
                    }

                    if (this.sourceId === "distribution") {
                        if (this.requiredUnits.every(Boolean)) {
                            this.showReport();
                        } else {
                            this.loadUnits();
                        }
                    } else if (this.sourceId === "orders") {
                        this.loadOrders();
                    }

                    this.setOrdersBoxMaxHeight();
                }
            },
            setOrdersBoxMaxHeight() {
                let elementsTotalHeight = 180;
                let backLinkHeight = (this.type !== 'total' && !this.isUserWithOneRestaurant) ? 29 : 0;

                this.ordersBoxMaxHeight = document.documentElement.clientHeight - elementsTotalHeight - backLinkHeight;
            }
        },
        filters: {
            daterangeText,
            dateText
        },
        created() {
            this.initUnitDetail();
            window.addEventListener("resize", this.setOrdersBoxMaxHeight);
        },
        watch: {
            id() {
                this.initUnitDetail();
            },
            reportDaterange() {
                if (this.sourceId === "orders") {
                    this.loadOrders();
                }
            },
            sourceId(sourceId) {
                if (sourceId === "orders") {
                    this.loadOrders();
                }
            },
            requiredUnits(requiredUnits) {
                if (!requiredUnits.every(Boolean)) {
                    this.loadUnits();
                }
            }
        },
        destroyed() {
            window.removeEventListener("resize", this.setOrdersBoxMaxHeight);
        }
    }
</script>

<style lang="scss">
    .unit-detail2__link {
        &_back {
            display: inline-block;
            margin-bottom: 5px;

            @include desktop {
                margin-bottom: 10px;
            }
        }
    }
    .unit-detail2__settings {
        margin-bottom: 15px;
    }
    .unit-detail2__dateranges {
        display: grid;
        grid-auto-columns: auto;
        grid-auto-rows: auto;
        grid-gap: 10px;
        justify-content: start;
        align-content: start;
        justify-items: start;
        align-items: start;
        margin-bottom: 15px;

        @include desktop {
            grid-auto-flow: column;
        }
    }
    .unit-detail2__toggles-pagination {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
    }
    .unit-detail2__toggles {
        display: grid;
        grid-auto-columns: auto;
        grid-auto-rows: auto;
        grid-gap: 10px;
        justify-content: start;
        align-content: start;
        justify-items: start;
        align-items: center;

        @include desktop {
            grid-auto-flow: column;
        }
    }
    .unit-detail2__select {
        min-width: 240px;
        padding: 4px 8px;
        margin-top: 15px;
        border-color: $gray-line;
        border-radius: 4px;

        &:focus {
            outline: none;
        }
    }
    .unit-detail2__box {
        padding: 8px 16px 16px;
        overflow-x: auto;

        &::-webkit-scrollbar {
            width: 8px;
            height: 8px;
            background-color: $gray-line;
        }
        &::-webkit-scrollbar-track {
            border-width: 0;
        }
        &::-webkit-scrollbar-thumb {
            background-color: $text-gray-secondary;
        }

        &_chart {
            padding: 16px;
        }

        &_charts {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 20px;
        }

        &_orders {
            position: relative;
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
            align-items: flex-start;

            overflow: auto;
            padding: 0 0 16px;

            &::before,
            &::after {
                content: "";
                display: block;
                width: 16px;
                flex-shrink: 0;
            }

            & thead {
                position: sticky;
                top: 0;
                z-index: 101;
                background-color: #fff;
            }

            & caption {
                padding: 8px;
            }
        }

        &_no-overflow {
            overflow: hidden;
        }

        & + & {
            margin-top: 30px;
        }
    }
    .unit-detail2__table {
        width: 100%;
    }
    .unit-detail2__table-caption {
        padding: 8px 8px 0;
        font-size: 24px;
        font-weight: bold;
    }
    .unit-detail2__table-column {
        --distribution-title-column-width: 12.5%;

        &_distribution {
            width: calc((100% - var(--distribution-title-column-width)) / 11);
        }

        &_distribution-title {
            width: var(--distribution-title-column-width);
        }

        &_order-department {
            min-width: 150px;
        }

        &_order-origin,
        &_order-type {
            min-width: 125px;
        }

        &_order-opex,
        &_order-op {
            min-width: 120px;
        }
    }
    .unit-detail2__table-row {
        &_body {
            .unit-detail2__table_striped &:nth-of-type(odd) {
                background-color: $background-gray-primary;

                & .unit-detail2__table-cell_num {
                    background-color: $background-gray-primary;
                }

                &:hover {
                    background-color: $gray-form;

                    & .unit-detail2__table-cell_num {
                        background-color: $gray-form;
                    }
                }
            }

            .unit-detail2__table_striped &:nth-of-type(even) {
                background-color: #fff;

                & .unit-detail2__table-cell_num {
                    background-color: #fff;
                }

                &:hover {
                    background-color: $gray-form;

                    & .unit-detail2__table-cell_num {
                        background-color: $gray-form;
                    }
                }
            }

            &:hover {
                background-color: $gray-form;
            }
        }

        &_pinned {
            background-color: $gray-form !important;
        }
    }
    .unit-detail2__table-cell-row {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
    }
    .unit-detail2__table-cell {
        font-size: 20px;

        &_text-small {
            font-size: 14px;
        }

        &_text-medium {
            font-size: 16px;
        }

        &_head {
            padding: 8px;
            white-space: nowrap;
        }

        &_body {
            padding: 16px 8px;
        }

        &_sortable {
            cursor: pointer;
        }

        &_title {
            font-weight: bold;
        }

        &_vat {
            vertical-align: top;
        }

        &_tar {
            text-align: right;
        }

        &_tac {
            text-align: center;
        }

        &_num {
            position: sticky;
            left: 0;

            &.unit-detail2__table-cell_head {
                background-color: #fff;
            }
        }
    }
    .unit-detail2__table-value {
        display: block;
    }
    .unit-detail2__table-icon {
        width: 16px;
        height: 16px;
        vertical-align: middle;
    }
    .unit-detail2__table-text {
        flex-grow: 1;
        vertical-align: middle;
    }
    .unit-detail2__orders-total-count {
        margin-bottom: 10px;
    }
    .unit-detail2__pagination {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-end;
        align-items: center;
    }
    .unit-detail2__orders-loader {
        position: absolute;
        inset: 0;
        z-index: 1001;
        background: rgba($gray-line, 0.75) url("/img/spinner.svg") center no-repeat;
    }
    .unit-detail2__orders-table-wrapper {
        flex-shrink: 0;
    }
</style>

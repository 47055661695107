<template>
    <label class="checkbox">
        <input class="checkbox__input"
            type="checkbox"
            :disabled="disabled"
            v-model="model"
        >
        <span class="checkbox__label"><slot></slot></span>
    </label>
</template>

<script>
    export default{
        name: "Checkbox",
        props: {
            value: {
                type: Boolean,
                required: true
            },
            disabled: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            model: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit("input", value);
                }
            }
        }
    }
</script>

<style lang="scss">
    .checkbox {
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: auto;
        justify-content: start;
        align-items: center;
        grid-gap: 5px;
        cursor: pointer;
    }
    .checkbox__input {
        width: 1em;
        height: 1em;
        accent-color: $red-primary;
    }
    .checkbox__label {
        line-height: 1;
    }
</style>
